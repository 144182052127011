import {
  CircularProgress,
  MenuItem,
  Select,
} from "@mui/material";
import { useAppDispatch, useTrading } from "features/store";
import { useListAccountsQuery } from "features/trading/accounts";
import { setCurrentAccount } from "features/trading/tradingSlice";
import { useEffect } from "react";

export const AccountPicker = () => {
  const { currentAccountId, currentClientId } = useTrading();
  const dispatch = useAppDispatch();

  const { data: accounts, isLoading } = useListAccountsQuery(
    {
      filters: { client: currentClientId },
      pagination: { page: 0, pageSize: 100 },
    },
    { skip: !currentClientId },
  );

  useEffect(() => {
    if (
      accounts &&
      accounts.results.length > 0 &&
      (!currentAccountId ||
        !accounts.results.some((account) => account.id === currentAccountId))
    ) {
      dispatch(setCurrentAccount(accounts.results[0].id));
    }
  }, [accounts, currentAccountId, dispatch, setCurrentAccount]);

  return !isLoading &&
    currentAccountId &&
    accounts &&
    accounts.results.some((account) => account.id === currentAccountId) ? (
    <Select
      labelId="account-label"
      label="Account"
      size="small"
      value={currentAccountId}
      sx={{width: 250, borderRadius: 5}}
      onChange={(event) => {
        dispatch(setCurrentAccount(event.target.value));
      }}
    >
      {accounts?.results.map((account) => (
        <MenuItem key={account.id} value={account.id}>
          {account.account_number}({account.custodian__name})
        </MenuItem>
      ))}
    </Select>
  ) : (
    <CircularProgress />
  );
};
