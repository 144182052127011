import { CircularProgress, MenuItem, Select } from "@mui/material";
import { useAppDispatch, useTrading } from "features/store";
import { useListStrategyGroupsQuery } from "features/trading/accounts";
import { setCurrentStrategyGroup } from "features/trading/tradingSlice";
import { getLastStrategyGroupId } from "helpers";
import { useEffect } from "react";

export const StrategyGroupPicker = () => {
  const { currentStrategyGroupId } = useTrading();
  const dispatch = useAppDispatch();

  const { data, isLoading } = useListStrategyGroupsQuery({
    pagination: { page: 0, pageSize: 100 },
  });

  useEffect(() => {
    if (data && data.results.length > 0) {
      const lastGroupId = getLastStrategyGroupId();
      const groupId =
        currentStrategyGroupId ||
        data.results.find((group) => group.id === lastGroupId)?.id ||
        data.results[0].id;
      dispatch(setCurrentStrategyGroup(groupId));
    }
  }, [data, currentStrategyGroupId, dispatch, setCurrentStrategyGroup]);

  return data ? (
    <Select
      labelId="account-label"
      label="Account"
      size="small"
      value={currentStrategyGroupId ? currentStrategyGroupId : ''}
      sx={{ width: 250, borderRadius: 5 }}
      onChange={(event) => {
        dispatch(setCurrentStrategyGroup(event.target.value));
      }}
    >
      {data.results.map((group) => (
        <MenuItem key={group.id} value={group.id}>
          {group.name}
        </MenuItem>
      ))}
    </Select>
  ) : (
    <CircularProgress />
  );
};
