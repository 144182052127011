import { LinearProgress } from "@mui/material";
import {
  DataGrid,
  DataGridProps,
  GridFilterModel,
  GridPaginationModel,
  GridSortDirection,
  GridSortModel,
  GridToolbar,
  GridValidRowModel,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import { useState } from "react";

interface DataGridComponent {
  <R extends GridValidRowModel = any>(
    props: DataGridProps<R> & React.RefAttributes<HTMLDivElement>,
  ): React.JSX.Element;
  propTypes?: any;
}

export const ServerDataGrid: DataGridComponent = (props) => {
  return (
    <DataGrid
      slots={{ toolbar: GridToolbar, loadingOverlay: LinearProgress }}
      slotProps={{
        ...props.slotProps,
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { sx: { width: 200 } },
        },
      }}
      paginationMode="server"
      sortingMode="server"
      filterMode="server"
      hideFooterSelectedRowCount
      pageSizeOptions={[10, 25, 50, 100]}
      {...props}
    />
  );
};

export const useSorting = (field: string, sort: GridSortDirection = "asc") =>
  useState<GridSortModel>([
    {
      field,
      sort,
    },
  ]);

export const usePagination = (pageSize = 10, page = 0) =>
  useState<GridPaginationModel>({
    pageSize,
    page,
  });

export const useGridFilters = (filters?: GridFilterModel) =>
  useState<GridFilterModel | undefined>(filters);

export const currencyFormatter = (params: any) => {
  const formattedValue = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(params.value);

  return <span>{formattedValue}</span>;
};

export const currencyFormatterRedGreen = (params: any) => {
  const formattedValue = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(params.value);

  return <span style={{color: params.value < 0 ? "red" : "green"}}>{formattedValue}</span>;
};

export const qtyFormatter = (params: any) => {
  const formattedValue = parseFloat(params.value || 0).toFixed(2);

  return <span>{formattedValue}</span>;
};

export const qtyFormatterWhole = (params: any) => {
  const formattedValue = parseFloat(params.value || 0).toFixed(0);

  return <span>{formattedValue}</span>;
};

export const availableQtyFormatter = (params: any) => {
  const { value } = params;
  let formattedValue = 'all';

  if (value !== null) {
    formattedValue = parseFloat(value).toFixed(0);
  }

  return <span>{formattedValue}</span>;
}

export const dateFormatter = (params: any) => {
  const formattedValue = dayjs(params.value).format("MM/DD/YYYY");

  return <span>{formattedValue}</span>;
}