import {
  Autocomplete,
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  Account,
  Client,
  Custodian,
  RegType,
  useGetClientQuery,
  useListClientsQuery,
  useListCustodiansQuery,
  useListRegTypesQuery,
  useNewAccountMutation,
  useUpdateAccountMutation,
} from "features/trading/accounts";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDebounce } from "use-debounce";
import { FormMode } from "constants/index";
import { useTrading } from "features/store";

interface Props {
  isOpened: boolean;
  onClose: () => void;
  account?: Account;
  mode: FormMode;
}

interface AccountInputs {
  account_number: string;
  client: number;
  custodian: number;
  reg_type: number;
}

export const AccountsModal = ({
  isOpened,
  onClose,
  account = undefined,
  mode = FormMode.CREATE,
}: Props) => {
  const isEditMode = useMemo(() => mode === FormMode.EDIT, [mode]);
  const { currentClientId } = useTrading();
  const [newAccount] = useNewAccountMutation();
  const [updateAccount] = useUpdateAccountMutation();
  const [clientSearchTerm, setClientSearchTerm] = useState<string>("");
  const [debounceClientSearchTerm] = useDebounce(clientSearchTerm, 250);
  const { data: client, isLoading: isClientLoading } = useGetClientQuery(
    currentClientId,
    { skip: !currentClientId },
  );
  const { data: clientsData, isLoading: isClientsLoading } =
    useListClientsQuery(
      { filters: { search: debounceClientSearchTerm } },
      { skip: !!currentClientId },
    );
  const { data: custodiansData, isLoading: isCustodiansLoading } =
    useListCustodiansQuery({ filters: { page_size: 100 } });
  const { data: regTypesData, isLoading: isRegTypesLoading } =
    useListRegTypesQuery({ filters: { page_size: 100 } });

  const defaultValues = useMemo(() => {
    const values = {
      account_number: "",
      client: -1,
      custodian: -1,
      reg_type: -1,
    };

    if (isEditMode && account) {
      const { account_number, client, custodian, reg_type } = account;
      return { account_number, client, custodian, reg_type };
    }

    if (currentClientId) {
      values.client = currentClientId;
    }

    if (custodiansData) {
      values.custodian = custodiansData.results[0]?.id || -1;
    }

    if (regTypesData) {
      values.reg_type = regTypesData.results[0]?.id || -1;
    }

    return values;
  }, [isEditMode, account, currentClientId, custodiansData, regTypesData]);

  const { register, handleSubmit, reset, setValue } = useForm<AccountInputs>({
    defaultValues,
  });
  const closeModal = () => {
    onClose();
    reset();
  };

  const onSubmit: SubmitHandler<AccountInputs> = (data) => {
    if (isEditMode && account) {
      updateAccount({ id: account.id, ...data });
    } else {
      newAccount({
        ...data,
        orion_account_id: Math.trunc(-1_0000_000 * Math.random()),
      });
    }
    closeModal();
  };

  useEffect(() => reset({ ...defaultValues }), [defaultValues]);

  const renderLockedClient = useCallback(() => {
    let id: number | undefined;
    let clientName: string | undefined;

    if (!isEditMode && currentClientId) {
      if (isClientLoading) {
        return <CircularProgress />;
      }

      if (client) {
        id = client.id;
        clientName = client.name;
      }
    } else {
      if (account) {
        id = account.client;
        clientName = account.client__name;
      }
    }

    if (id && clientName) {
      return (
        <FormControl sx={{ margin: 1 }}>
          <InputLabel>Client</InputLabel>
          <Select label="Client" value={id} disabled>
            <MenuItem key={id} value={id}>
              {clientName}
            </MenuItem>
          </Select>
        </FormControl>
      );
    }

    return null;
  }, [isEditMode, account, currentClientId, client, isClientLoading]);

  const renderClientSelect = useCallback(() => {
    const lockedClientSelect = renderLockedClient();
    if (lockedClientSelect) {
      return lockedClientSelect;
    }

    if (isClientsLoading || !clientsData) {
      return <CircularProgress />;
    }

    const { results: clients } = clientsData;

    return (
      <FormControl sx={{ margin: 1 }}>
        <Autocomplete
          disablePortal
          options={clients.map((client: Client) => ({
            id: client.id,
            label: client.name,
          }))}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Client"
              onChange={(e) => setClientSearchTerm(e.target.value)}
            />
          )}
          onChange={(_, record) => setValue("client", record?.id || 0)}
        />
      </FormControl>
    );
  }, [isClientsLoading, clientsData, renderLockedClient, setClientSearchTerm, setValue]);

  const renderCustodianSelect = useCallback(() => {
    if (isCustodiansLoading || !custodiansData) {
      return <CircularProgress />;
    }

    const { results: custodians } = custodiansData;

    return (
      <FormControl sx={{ margin: 1 }}>
        <InputLabel>Custodian</InputLabel>
        <Select
          label="Custodian"
          {...register("custodian")}
          defaultValue={defaultValues.custodian}
        >
          {custodians.map((custodian: Custodian) => (
            <MenuItem key={custodian.id} value={custodian.id}>
              {custodian.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }, [isCustodiansLoading, custodiansData, defaultValues, register]);

  const renderRegTypeSelect = useCallback(() => {
    if (isRegTypesLoading || !regTypesData) {
      return <CircularProgress />;
    }

    const { results: regTypes } = regTypesData;

    return (
      <FormControl sx={{ margin: 1 }}>
        <InputLabel>Reg Type</InputLabel>
        <Select
          label="Reg Type"
          {...register("reg_type")}
          defaultValue={defaultValues.reg_type}
        >
          {regTypes.map((regType: RegType) => (
            <MenuItem key={regType.id} value={regType.id}>
              {regType.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }, [isRegTypesLoading, regTypesData, defaultValues, register]);

  return (
    <Modal open={isOpened} onClose={closeModal}>
      <Stack height="100%" alignItems="center" justifyContent="center">
        <Box
          style={{ width: "600px" }}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Card>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h5" component="h5" sx={{ padding: 1 }}>
                  {`${isEditMode ? "Edit" : "Add new"} account`}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={closeModal}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <FormGroup>
              {renderClientSelect()}
              <FormControl sx={{ margin: 1 }}>
                <TextField label="Number" {...register("account_number")} />
              </FormControl>
              {renderCustodianSelect()}
              {renderRegTypeSelect()}
              <Button type="submit" variant="contained" sx={{ margin: 1 }}>
                {isEditMode ? "Save" : "Add"}
              </Button>
            </FormGroup>
          </Card>
        </Box>
      </Stack>
    </Modal>
  );
};
