import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AccountStrategy,
  Advisor,
  Strategy,
  StrategyGroup,
  useAddAccountStrategyMutation,
  useListAccountStrategiesQuery,
  useListAdvisorsQuery,
  useListStrategiesQuery,
  useListStrategyGroupsQuery,
} from "features/trading/accounts";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  ManagementType,
  ReportingFrequency,
  StrategyGroupEnum,
  UnderlyingType,
} from "constants/index";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

interface Props {
  accountId: number;
  strategy?: Strategy;
  isOpened: boolean;
  onClose: () => void;
}

interface AccountStrategyInputs {
  strategy: number;
  composite: boolean;
  reporting: string;
  reinvest: boolean;
  keyword: boolean;
  underlying: string;
  management: string;
  advisor: number;
  notes: string;
}

export const AddAccountStrategyModal = ({
  isOpened,
  onClose,
  accountId,
  strategy,
}: Props) => {
  const { data: accountStratsData, isLoading: isLoadingAccountStrats } =
    useListAccountStrategiesQuery(
      {
        filters: { account_id: accountId },
      },
      {
        skip: !accountId,
      },
    );

  const { data: groupsData, isLoading: isLoadingGroups } =
    useListStrategyGroupsQuery({});
  const { data: stratsData, isLoading: isLoadingStrats } =
    useListStrategiesQuery({
      filters: {
        page_size: 100,
      },
    });
  const { data: advisorsData, isLoading: isAdvisorsLoading } =
    useListAdvisorsQuery({ filters: { page_size: 100 } });
  const [selectedGroupId, setSelectedGroupId] = useState<any>(1);
  const defaultValues = useMemo(() => {
    const values = {
      strategy: strategy?.id || -1,
      composite: true,
      reporting: ReportingFrequency.MONTHLY,
      reinvest: true,
      keyword: true,
      underlying: UnderlyingType.COVERED,
      management: ManagementType.DIRECT,
      advisor: -1,
      notes: "",
    };

    if (!isAdvisorsLoading && advisorsData) {
      values.advisor = advisorsData.results[0]?.id || 0;
    }

    return values;
  }, [strategy, isAdvisorsLoading, advisorsData]);
  const { register, handleSubmit, watch, setValue, reset } =
    useForm<AccountStrategyInputs>({ defaultValues });
  const watched = watch();
  const [addAccountStrategy] = useAddAccountStrategyMutation();
  const closeModal = () => {
    onClose();
    reset();
    setSelectedGroupId(StrategyGroupEnum.STANDARD);
  };

  useEffect(() => {
    if (isOpened) {
      setSelectedGroupId(
        strategy?.strategy_group || StrategyGroupEnum.STANDARD,
      );
      setValue("strategy", strategy?.id || -1);
    }
  }, [strategy, isOpened]);

  const groupedStrats = useMemo(() => {
    if (isLoadingStrats || !stratsData) {
      return [];
    }

    const { results: strats } = stratsData;
    return strats.reduce((acc: any, strat: Strategy) => {
      if (acc[strat.strategy_group]) {
        acc[strat.strategy_group].push(strat);
      } else {
        acc[strat.strategy_group] = [strat];
      }

      return acc;
    }, {});
  }, [isLoadingStrats, stratsData]);

  const accountStrats = useMemo(() => {
    if (isLoadingAccountStrats || !accountStratsData) {
      return {};
    }

    const { results } = accountStratsData;
    return results.reduce(
      (acc: { [key: number]: boolean }, strat: AccountStrategy) => {
        const { strategy: id } = strat;
        if (!acc[id]) {
          acc[id] = true;
        }
        return acc;
      },
      {},
    );
  }, [isLoadingAccountStrats, accountStratsData]);

  const onSubmit: SubmitHandler<AccountStrategyInputs> = useCallback(
    (data) => {
      if (selectedGroupId === StrategyGroupEnum.STANDARD) {
        addAccountStrategy({
          ...data,
          account: accountId,
        } as Partial<AccountStrategyInputs>);
      } else {
        const groupStrats = groupedStrats[selectedGroupId].filter(
          (strat: Strategy) => !accountStrats[strat.id],
        );
        groupStrats.map((strat: Strategy) => {
          addAccountStrategy({
            ...data,
            account: accountId,
            strategy: strat.id,
          } as Partial<AccountStrategyInputs>);
        });
      }

      closeModal();
    },
    [
      selectedGroupId,
      addAccountStrategy,
      accountId,
      groupedStrats,
      accountStrats,
    ],
  );

  useEffect(() => reset({ ...defaultValues }), [defaultValues]);

  const renderGroupSelect = useCallback(() => {
    if (isLoadingGroups || !groupsData) {
      return <CircularProgress />;
    }

    const { results: groups } = groupsData;

    return (
      <FormControl sx={{ margin: 1 }}>
        <InputLabel>Group</InputLabel>
        <Select
          label="Group"
          value={selectedGroupId}
          onChange={(event) => {
            setSelectedGroupId(Number(event.target.value));
          }}
          disabled={!!strategy}
        >
          {groups.map((group: StrategyGroup) => (
            <MenuItem key={group.id} value={group.id}>
              {group.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }, [selectedGroupId, groupsData, isLoadingGroups, strategy]);

  const isStrategyDisabled = useCallback(
    (strat: Strategy) => {
      return !!accountStrats[strat.id];
    },
    [accountStrats],
  );

  const renderStratsSelect = useCallback(() => {
    if (isLoadingStrats || !stratsData) {
      return <CircularProgress />;
    }

    return (
      <FormControl sx={{ margin: 1 }}>
        <InputLabel>Strategy</InputLabel>
        <Select
          labelId="strategy-label"
          label="Strategy"
          value={watched.strategy}
          {...register("strategy")}
          disabled={!!strategy}
        >
          {groupedStrats[selectedGroupId || 0]?.map((strat: Strategy) => (
            <MenuItem
              key={strat.id}
              value={strat.id}
              disabled={isStrategyDisabled(strat)}
            >
              {strat.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }, [
    watched.strategy,
    groupedStrats,
    selectedGroupId,
    stratsData,
    isLoadingStrats,
    strategy,
    isStrategyDisabled,
  ]);

  const renderAdvisorSelect = useCallback(() => {
    if (isAdvisorsLoading || !advisorsData) {
      return <CircularProgress />;
    }

    const { results: advisors } = advisorsData;

    return (
      <FormControl sx={{ margin: 1 }}>
        <InputLabel>Advisor</InputLabel>
        <Select
          label="Advisor"
          defaultValue={defaultValues.advisor}
          {...register("advisor")}
        >
          {advisors.map((advisor: Advisor) => (
            <MenuItem key={advisor.id} value={advisor.id}>
              {advisor.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }, [isAdvisorsLoading, advisorsData, defaultValues, register]);

  useEffect(() => {
    if (selectedGroupId) {
      const groupStrats = groupedStrats[selectedGroupId];
      setValue(
        "strategy",
        groupStrats && groupStrats.length > 0 ? groupStrats[0].id : "",
      );
    }
  }, [selectedGroupId]);

  return (
    <Modal open={isOpened} onClose={closeModal}>
      <Stack height="100%" alignItems="center" justifyContent="center">
        <Box
          style={{ width: "600px" }}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Card>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h5" component="h5" sx={{ padding: 1 }}>
                  Add Account Strategy
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={closeModal}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <FormGroup>
              {renderGroupSelect()}
              {selectedGroupId === StrategyGroupEnum.STANDARD &&
                renderStratsSelect()}
              <FormControl sx={{ margin: 1 }}>
                <InputLabel>Reporting</InputLabel>
                <Select
                  label="Reporting"
                  defaultValue={defaultValues.reporting}
                  fullWidth
                  {...register("reporting")}
                >
                  {Object.values(ReportingFrequency).map((v) => (
                    <MenuItem key={v} value={v}>
                      {v}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ margin: 1 }}>
                <InputLabel>Underlying</InputLabel>
                <Select
                  label="Underlying"
                  defaultValue={defaultValues.underlying}
                  {...register("underlying")}
                >
                  {Object.values(UnderlyingType).map((v) => (
                    <MenuItem key={v} value={v}>
                      {v}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ margin: 1 }}>
                <InputLabel>Management</InputLabel>
                <Select
                  label="Management"
                  defaultValue={defaultValues.management}
                  {...register("management")}
                >
                  {Object.values(ManagementType).map((v) => (
                    <MenuItem key={v} value={v}>
                      {v}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {renderAdvisorSelect()}
              <FormControlLabel
                sx={{ paddingX: 1 }}
                control={
                  <Tooltip title="Include in performance analysis">
                    <Switch
                      defaultChecked={defaultValues.composite}
                      {...register("composite")}
                    />
                  </Tooltip>
                }
                label="Composite"
              />
              <FormControlLabel
                sx={{ paddingX: 1 }}
                control={
                  <Tooltip title="Reinvest dividends and covered calls proceeds">
                    <Switch
                      defaultChecked={defaultValues.reinvest}
                      {...register("reinvest")}
                    />
                  </Tooltip>
                }
                label="Reinvest $"
              />
              <FormControlLabel
                sx={{ paddingX: 1 }}
                control={
                  <Switch
                    defaultChecked={defaultValues.keyword}
                    {...register("keyword")}
                  />
                }
                label="Keyword"
              />
              <TextField
                sx={{ padding: 1 }}
                label="Composite Inclusion/Exclusion Notes"
                multiline
                {...register("notes")}
              />
              <Box display="flex">
                <Button
                  startIcon={<CloseIcon />}
                  fullWidth
                  sx={{ marginX: 4, marginY: 1 }}
                  variant="contained"
                  color="error"
                  onClick={closeModal}
                >
                  Cancel
                </Button>
                <Button
                  startIcon={<AddIcon />}
                  fullWidth
                  sx={{ marginX: 4, marginY: 1 }}
                  type="submit"
                  variant="contained"
                >
                  Add
                </Button>
              </Box>
            </FormGroup>
          </Card>
        </Box>
      </Stack>
    </Modal>
  );
};
