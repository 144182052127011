import { Card, Grid } from "@mui/material";
import NonOpticPositionsList from "components/holdings/NonOpticPositionsList";
import Layout from "components/Layout";

const NonOpticPage = () => {
  return (
    <Layout title="Non Optic Positons">
      <Card>
        <Grid container>
          <Grid item xs={12} padding={2}>
            <NonOpticPositionsList />
          </Grid>
        </Grid>
      </Card>
    </Layout>
  );
};

export default NonOpticPage;
