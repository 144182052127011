import { Box, CircularProgress, Typography } from "@mui/material";
import {
  ServerDataGrid,
  currencyFormatterRedGreen,
  dateFormatter,
  useGridFilters,
  usePagination,
  useSorting,
} from "components/ui/ServerDataGrid";
import { useTrading } from "features/store";
import { Position, useListPositionsQuery } from "features/trading/accounts";
import {
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import SettlementDatePicker from "./SettlementDatePicker";
import { AccountPicker } from "./AccountPicker";
import { qtyFormatter, currencyFormatter } from "components/ui/ServerDataGrid";
import { asCurrency } from "components/ui/formatters";

const columns: GridColDef<Position>[] = [
  { field: "id", headerName: "ID", width: 90 },
  { field: "asset__symbol", headerName: "Ticker", width: 90 },
  { field: "asset__name", headerName: "Name", width: 200 },
  {
    field: "quantity",
    headerName: "Quantity",
    width: 80,
    type: "number",
    renderCell: qtyFormatter,
  },
  {
    field: "price",
    headerName: "Price",
    width: 80,
    type: "number",
    renderCell: currencyFormatter,
  },
  {
    field: "market_value",
    headerName: "Market Value",
    width: 100,
    type: "number",
    renderCell: currencyFormatter,
  },
  {
    field: "weight",
    headerName: "Weight",
    width: 100,
    type: "number",
    renderCell: qtyFormatter,
  },
  {
    field: "cost_basis",
    headerName: "Cost Basis",
    width: 100,
    type: "number",
    renderCell: currencyFormatter,
  },
  {
    field: "unrealized_gain_loss",
    headerName: "Unrealized P/L",
    width: 100,
    type: "number",
    renderCell: currencyFormatterRedGreen,
  },
  {
    field: "purchase_date",
    headerName: "Purchased",
    width: 100,
    renderCell: dateFormatter,
    align: "right",
  },
  { field: "asset__asset_category__name", headerName: "Category", width: 100 },
  { field: "asset__asset_class__name", headerName: "Class", width: 100 },
  { field: "asset__product_type__name", headerName: "Type", width: 100 },
  { field: "asset__product_sub_type__name", headerName: "SubType", width: 100 },
];

const HoldingsToolBar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter sx={{ width: 150 }} />
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      {/* <Box sx={{ flexGrow: 1 }} /> */}
      <AccountPicker />
      <SettlementDatePicker />
    </GridToolbarContainer>
  );
};

export default function HoldingsList({
  showTotals = false,
}: { showTotals?: boolean } = {}) {
  const { currentAccountId, currentSettlementDate } = useTrading();

  const [sorting, setSorting] = useSorting("asset__symbol");
  const [pagination, setPagination] = usePagination();
  const [gridFilters, setGridFilters] = useGridFilters();

  const { data, isLoading } = useListPositionsQuery(
    {
      pagination: showTotals ? { page: 0, pageSize: 1000 } : pagination,
      sorting,
      gridFilters,
      filters: {
        as_of_date: currentSettlementDate,
        account: currentAccountId,
      },
    },
    { skip: !currentSettlementDate || !currentAccountId },
  );

  const totals = data?.results?.reduce(
    (acc, row) => {
      acc.market_value += parseFloat(row.market_value || "0");
      acc.unrealized_gain_loss += parseFloat(row.unrealized_gain_loss || "0");
      return acc;
    },
    { market_value: 0, unrealized_gain_loss: 0 },
  );

  return (
    !isLoading ? (
      <Box>
        <ServerDataGrid
          slots={{ toolbar: HoldingsToolBar }}
          density="compact"
          columns={columns}
          rows={data?.results || []}
          loading={isLoading}
          rowCount={data?.count || 0}
          paginationModel={pagination}
          paginationMode={showTotals ? "client" : "server"}
          sortModel={sorting}
          filterModel={gridFilters}
          onPaginationModelChange={setPagination}
          onSortModelChange={setSorting}
          onFilterModelChange={setGridFilters}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
                asset__asset_category__name: false,
                asset__asset_class__name: false,
                asset__product_sub_type__name: true,
                asset__product_type__name: true,
              },
            },
          }}
        />
        <Typography variant="h6">
          Total Value: {asCurrency(totals?.market_value!)}. Unrealized
          P/L:&nbsp;
          {asCurrency(totals?.unrealized_gain_loss!)}
        </Typography>
      </Box>
    ) : <CircularProgress />
  );
}
