import ClientsList from "components/clients/ClientsList";
import Layout from "../components/Layout";
import { Grid } from "@mui/material";
import ClientDetails from "components/clients/ClientDetails";
import { useTrading } from "features/store";

export default function ClientsPage() {
  const { currentClientId } = useTrading();

  return (
    <Layout title="Clients" content="Clients page">
      <Grid container>
        <Grid item xs={12} md={4}>
          <ClientsList />
        </Grid>
        {currentClientId && (
          <Grid item xs={12} md={8}>
            <ClientDetails />
          </Grid>
        )}
      </Grid>
    </Layout>
  );
}
