import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Card, CircularProgress, Typography, Tab } from "@mui/material";
import { useTrading } from "features/store";
import { useGetClientQuery } from "features/trading/accounts";
import { useState } from "react";
import AccountsList from "./AccountsList";
import HoldingsList from "components/holdings/HoldingsList";
import ClientProfile from "./ClientProfile";
import Strategy from "./Strategy";

export default function ClientDetails() {
  const { currentClientId } = useTrading();
  const { data: client, isFetching } = useGetClientQuery(currentClientId, {
    skip: !currentClientId,
  });
  const [tab, setTab] = useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  return (
    // <Card>
    <Box>
      {client && !isFetching ? (
        <Box display={"flex"} flexDirection={"column"}>
          <Box margin={1} display={"flex"} alignItems={"center"}>
            <Typography variant="h5" flexGrow={1}>
              {client.name}
            </Typography>
            <Typography>
              Optic ID: {client.id}. Orion ID: {client.orion_client_id}
            </Typography>
          </Box>
          <TabContext value={tab}>
            <Box borderBottom={1} borderColor="divider">
              <TabList onChange={handleChange}>
                <Tab label="Details" value="1" />
                <Tab label="Accounts" value="2" />
                <Tab label="Holdings" value="3" />
                <Tab label="Strategy" value="4" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Card sx={{padding: 2}}>
                <ClientProfile clientId={currentClientId} />
              </Card>
            </TabPanel>
            <TabPanel value="2">
              <Card>
                <Box margin={1}>
                  <AccountsList clientId={currentClientId} />
                </Box>
              </Card>
            </TabPanel>
            <TabPanel value="3">
              <Card>
                <HoldingsList showTotals />
              </Card>
            </TabPanel>
            <TabPanel value="4" >
              <Strategy />
            </TabPanel>
          </TabContext>
        </Box>
      ) : (
        <CircularProgress />
      )}
    </Box>
    // </Card>
  );
}
