import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { apiWithAuth, LoginResponse } from "./auth";

export interface AuthState {
  isAuthenticated?: boolean;
  isLoading?: boolean;
  user?: any;
  accessToken?: string | null;
  refreshToken?: string | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  isLoading: true,
  user: null,
  accessToken: localStorage.getItem("accessToken"),
  refreshToken: localStorage.getItem("refreshToken"),
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(apiWithAuth.endpoints.login.matchPending, (state) => {
        state.isLoading = true;
      })
      .addMatcher(apiWithAuth.endpoints.login.matchFulfilled, (state, action: PayloadAction<LoginResponse>) => {
        state.isAuthenticated = true;
        state.isLoading = false;
        state.accessToken = action.payload.access;
        state.refreshToken = action.payload.refresh;
      })
      .addMatcher(apiWithAuth.endpoints.login.matchRejected, (state) => {
        state.isAuthenticated = false;
        state.isLoading = false;
      })
      .addMatcher(apiWithAuth.endpoints.checkAuth.matchPending, (state) => {
        state.isLoading = true;
      })
      .addMatcher(apiWithAuth.endpoints.checkAuth.matchFulfilled, (state) => {
        state.isAuthenticated = true;
        state.isLoading = false;
      })
      .addMatcher(apiWithAuth.endpoints.checkAuth.matchRejected, (state) => {
        state.isAuthenticated = false;
        state.isLoading = false;
        state.accessToken = null;
      })
      .addMatcher(apiWithAuth.endpoints.logout.matchFulfilled, (state) => {
        state.isAuthenticated = false;
        state.isLoading = false;
        state.user = null;
        state.accessToken = null;
        state.refreshToken = null;
      })
      .addMatcher(apiWithAuth.endpoints.getUser.matchFulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
  },
});

export default authSlice.reducer;
