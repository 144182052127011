import { theme } from "components/hoc/Theme";
import Layout from "../components/Layout";
import Login from "../components/Login";
import { Box, Container, ThemeProvider } from "@mui/material";
import { LOGO_URL } from "config";

export default function LoginPage() {
  return (
    <ThemeProvider theme={theme}>
      <Box
        minHeight={"100vh"}
        bgcolor={"#F7FCFF"}
        alignItems={"center"}
        justifyContent={"center"}
        display={"flex"}
        overflow={"hidden"}
        position={"relative"}
      >
        <div
          style={{
            width: "45vh",
            height: "45vh",
            left: "10vw",
            bottom: "0vh",
            position: "absolute",
            background: "#3399CC",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: 9999,
            zIndex: 1,
          }}
        />
        <div
          style={{
            width: "120vh",
            height: "120vh",
            right: "-20vw",
            top: "-60vh",
            position: "absolute",
            background: "#3399CC",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: 9999,
            zIndex: 1,
          }}
        />

        <Container component="main" maxWidth="sm" sx={{zIndex:2}}>
          <Login />
        </Container>

      </Box>
    </ThemeProvider>
  );
}
