import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import { store } from './features/store';
import { SnackbarProvider } from 'notistack';
import { NOTISTACK_AUTO_HIDE_DURATION, NOTISTACK_MAX_SNACK } from 'constants/index';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store} >
    <SnackbarProvider autoHideDuration={NOTISTACK_AUTO_HIDE_DURATION} maxSnack={NOTISTACK_MAX_SNACK}>
      <App />
    </SnackbarProvider>
  </Provider >
);
