import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { SubmitHandler, useForm } from "react-hook-form";
import { Client, Representative, useAddNewClientMutation, useListRepresentativesQuery, useUpdateClientMutation } from "features/trading/accounts";
import { FormMode } from "constants/index";

interface Props {
  isOpened: boolean;
  onClose: () => void;
  client?: Client;
  mode: FormMode;
}

interface ClientInputs {
  name: string;
  representative: number;
}

const defaultValues = {
  name: '',
  representative: 1
};

export const ClientModal = ({
  isOpened,
  onClose,
  client = undefined,
  mode = FormMode.CREATE
}: Props) => {
  const { data: repsData, isLoading: isRepsLoading } = useListRepresentativesQuery({ filters: { page_size: 100 } });
  const { register, handleSubmit, reset } =
    useForm<ClientInputs>({ defaultValues });
  const closeModal = () => {
    onClose();
    reset();
  };
  const [addNewClient] = useAddNewClientMutation();
  const [updateClient] = useUpdateClientMutation();
  const onSubmit: SubmitHandler<ClientInputs> = (data) => {
    if (mode === FormMode.EDIT && client) {
      updateClient({ id: client.id,  ...data });
    } else {
      addNewClient({ ...data, orion_client_id: Math.trunc(-1_0000_000 * Math.random()) });
    }
    closeModal();
  };

  useEffect(() => {
    if (mode == FormMode.EDIT && client) {
      const { name, representative } = client;
      reset({ name, representative })
    } else {
      reset({ ...defaultValues });
    }
  }, [client, mode]);

  const renderRepresentativesSelect = useCallback(() => {
    if (isRepsLoading || !repsData) {
      return <CircularProgress />;
    }

    const { results: reps } = repsData;

    return (
      <FormControl sx={{ margin: 1}}>
        <InputLabel>Representative</InputLabel>
        <Select
          label="Representative"
          defaultValue={mode === FormMode.EDIT && client ? client.representative : defaultValues.representative}
          {...register("representative")}
          >
            {reps.map((rep: Representative) => (
              <MenuItem key={rep.id} value={rep.id}>
                {rep.name}
              </MenuItem>
            ))}
          </Select>
      </FormControl>
    );
  }, [isRepsLoading, repsData, mode, client]);

  return (
    <Modal open={isOpened} onClose={closeModal}>
      <Stack height="100%" alignItems="center" justifyContent="center">
        <Box
          style={{ width: "600px" }}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Card>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h5" component="h5" sx={{ padding: 1}}>
                  {`${ mode === FormMode.EDIT ? "Edit" : "Add new"} client`}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={closeModal}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <FormGroup>
              <FormControl sx={{ margin: 1}}>
                <TextField
                  label="Name"
                  {...register("name")}
                  defaultValue={defaultValues.name}
                />
              </FormControl>
              {renderRepresentativesSelect()}
              <Button type="submit" variant="contained" sx={{ margin: 1}}>
                {mode === FormMode.EDIT ? "Save" : "Add"}
              </Button>
            </FormGroup>
          </Card>
        </Box>
      </Stack>
    </Modal>
  );
};
