import { Refresh } from "@mui/icons-material";
import {
  Box,
  Card,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridValueFormatterParams } from "@mui/x-data-grid";
import { AccountPicker } from "components/holdings/AccountPicker";
import { AccountStrategyPicker } from "components/holdings/AccountStrategyPicker";
import { YesNoSelect } from "components/ui/YesNoSelect";
import dayjs from "dayjs";
import { useAppDispatch, useTrading } from "features/store";
import { AccountStrategy, useRemoveAccountStrategyMutation } from "features/trading/accounts";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { AddAccountStrategyModal } from "components/holdings/AddAccountStrategyModal";
import TrashIcon from "@mui/icons-material/Delete";
import { setSelectedAccountStrategy } from "features/trading/tradingSlice";

const columns: GridColDef[] = [
  {
    field: "composite",
    headerName: "Composite",
    type: "boolean",
    renderCell: (params: any) => <YesNoSelect value={params.value} />,
  },
  {
    field: "reporting",
    headerName: "Reporting",
    type: "string",
  },
  {
    field: "reinvest",
    headerName: "Reinvest $",
    type: "boolean",
    renderCell: (params: any) => <YesNoSelect value={params.value} />,
  },
  {
    field: "keywork",
    headerName: "Keyword",
    type: "boolean",
    renderCell: (params: any) => <YesNoSelect value={params.value} />,
  },
  {
    field: "underlying",
    headerName: "Underlying",
    type: "string",
    valueFormatter: ({ value }: GridValueFormatterParams) => value || 'N/A',
  },
  {
    field: "management",
    headerName: "Management",
    type: "string",
    valueFormatter: ({ value }: GridValueFormatterParams) => value || 'N/A',
  },
  {
    field: "advisor__name",
    headerName: "Advisor",
    type: "string",
    valueFormatter: ({ value }: GridValueFormatterParams) => value || 'N/A',
  },
].map((col) => ({
  ...col,
  align: "center"
}));

const DUMMY_COMPOSITE_HISTORY = [
  {
    id: 1,
    as_of_date: "2024-01-14",
    composite: true,
    reporting: "Quarterly",
    reinvest: true,
    keywork: true,
    underlying: "Covered",
    management: "Direct",
    advisor__name: "David Watts",
    notes: "Notes go here, them might be long I'm not sure",
  },
  {
    id: 2,
    as_of_date: "2023-12-15",
    composite: false,
    reporting: "Annual",
    reinvest: true,
    keywork: true,
    underlying: "Uncovered",
    management: "Yes",
    advisor__name: "David Watts",
    notes: "Did not look like a good idea",
  },
  {
    id: 3,
    as_of_date: "2023-11-13",
    composite: true,
    reporting: "Monthly",
    reinvest: true,
    keywork: true,
    underlying: "Covered",
    management: "Direct",
    advisor__name: "David Watts",
    notes: "Nothing to say here",
  },
];

export default function Strategy() {
  const dispatch = useAppDispatch();
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const { selectedAccountStrategy, currentAccountId } = useTrading();
  const [removeAccountStrategy] = useRemoveAccountStrategyMutation();
  let rows: Partial<AccountStrategy>[] = [...(selectedAccountStrategy ? [selectedAccountStrategy] : []), ...(showHistory ? DUMMY_COMPOSITE_HISTORY : [])];

  const [isOpenedAddModal, setIsOpenAddModal] = useState<boolean>(false);
  const openAddModal = () => setIsOpenAddModal(true);
  const onAddModalClose = () => setIsOpenAddModal(false);
  const onRemoveAccountStrategy = (id?: number) => () => {
    if (id !== undefined) {
      removeAccountStrategy(id);
    }
    dispatch(setSelectedAccountStrategy(undefined));
    setShowHistory(false);
  };

  return (
    <Box>
      <Card>
        <Box display={"flex"}>
          <AccountPicker />
          <Box marginLeft={1}>
            <AccountStrategyPicker />
          </Box>
          <Box flexGrow={1} marginLeft={1}>
            <Tooltip title="Remove account from selected strategy">
              <IconButton onClick={onRemoveAccountStrategy(selectedAccountStrategy?.id)} disabled={!selectedAccountStrategy}>
                <TrashIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Add new strategy to account">
              <IconButton onClick={openAddModal}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <IconButton onClick={() => setShowHistory((oldValue) => !oldValue)} disabled={!selectedAccountStrategy}>
            <Typography>{showHistory ? "Hide" : "Show"} History</Typography>
            <Refresh />
          </IconButton>
        </Box>
      </Card>
      {selectedAccountStrategy ? rows.map((row, index) => {
        return (
          <Card key={row.id}>
            <Box margin={1}>
              <Typography variant="body1" color={"green"}>
                As of {dayjs().format("YYYY-MM-DD")}
              </Typography>
              <DataGrid hideFooter columns={columns} rows={[rows[index]]} />
              <Typography
                variant="body2"
                color={"primary.main"}
                fontWeight={"bold"}
                marginY={2}
              >
                Composite Inclusion/Exclusion Notes
              </Typography>
              <Divider />
              {index == 0 ? (
                <TextField multiline fullWidth value={row.notes} />
              ) : (
                <Typography variant="body2" marginY={2}>
                  {row.notes}
                </Typography>
              )}
              {/* <Typography variant="body2" marginY={2}>
                {row.notes}
              </Typography> */}
            </Box>
          </Card>
        );
        }) : (
          <Card>
            <Stack height="100%" alignItems="center" justifyContent="center" sx={{ padding: 2, marginY: 2 }}>
                Select account strategy
            </Stack>
          </Card>
        )
      }
      <Card sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography fontWeight={"bold"} color={"primary.main"}>
              Accumulated Cashflow:
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>$2,493</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography color={"green"}>*Since Inception</Typography>{" "}
          </Grid>
          <Grid item xs={3}>
            <Typography fontWeight={"bold"} color={"primary.main"}>
              Current Unrealized G/L:
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>$12,493</Typography>
          </Grid>
          <Grid item xs={7}></Grid>
          <Grid item xs={3}>
            <Typography fontWeight={"bold"} color={"primary.main"}>
              1 Yr, 2 Yr, Drop Cashflow:
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>$29,492</Typography>
          </Grid>
          <Grid item xs={7}></Grid>
        </Grid>
      </Card>
      <AddAccountStrategyModal isOpened={isOpenedAddModal} onClose={onAddModalClose} accountId={currentAccountId!}/>
    </Box>
  );
}
