import { alpha, createTheme, getContrastRatio } from "@mui/material/styles";
import "@mui/x-data-grid/themeAugmentation";
import "@mui/lab/themeAugmentation";

import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";

import { LinkProps } from "@mui/material/Link";
import React from "react";

const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (Material UI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

const lightBlueMain = "#3399CC";
const lightBlueBase = alpha(lightBlueMain, 0.7);

export const theme = createTheme({
  typography: {
    fontFamily: '"Poppins", sans-serif',
    h5: {
      fontWeight: "bold",
    }
  },
  palette: {
    // background: { default: "#F7FCFF" },
    primary: {
      main: lightBlueMain, // Custom primary color
      light: alpha(lightBlueBase, 0.5),
      dark: alpha(lightBlueBase, 0.9),
      contrastText:
        getContrastRatio(lightBlueMain, "#fff") > 4.5 ? "#fff" : "#111",
    },
    secondary: {
      main: "#F0F0F0", // Custom secondary color
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          padding: 4,
          margin: 4,
          borderRadius: 12,
          boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2)",
          backgroundColor: "white",
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
          // padding: 4,
          // margin: 4,
          // borderRadius: 12,
          // boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2)',
          // backgroundColor: "white"
        },
        columnHeaderTitle: {
          fontWeight: "bold",
          color: lightBlueMain,
        },
        columnHeader: {
          borderBottom: `1px solid ${lightBlueMain}`,
          borderColor: lightBlueMain,
          borderBottomColor: lightBlueMain,
        }
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: alpha(lightBlueBase, 0.5),
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#F7FCFF", // Custom background color
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "hover",
        marginRight: "10px",
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiTabPanel:{
      styleOverrides: {
        root: {
          padding: 1,
          // margin: 0,
        },
      },
    },
    
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
        },
      },
      defaultProps: {
        autoCapitalize: "none",
      },
    },
  },
});
