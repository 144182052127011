import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useAppDispatch, useTrading } from "features/store";
import { useListSettlementDatesQuery } from "features/trading/accounts";
import { setCurrentSettlementDate } from "features/trading/tradingSlice";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const MAX_SETTLEMENT_DATES = 365;

export default function SettlementDatePicker() {
  const { isLoading: isLoadingSettlementDates } = useListSettlementDatesQuery({
    pagination: { page: 0, pageSize: MAX_SETTLEMENT_DATES },
  });
  const { currentSettlementDate, settlementDates } = useTrading();
  const dispatch = useAppDispatch();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label="As of Date"
        value={
          currentSettlementDate ? dayjs(currentSettlementDate, "YYYY-MM-DD") : null
        }
        loading={isLoadingSettlementDates || settlementDates === undefined}
        shouldDisableDate={(date) =>
          settlementDates !== undefined &&
          settlementDates[date.format("YYYY-MM-DD")] === undefined
        }
        onChange={(date) => {
          dispatch(setCurrentSettlementDate(date?.format("YYYY-MM-DD") || null));
        }}
        sx={{ width: 170 }}
        slotProps={{ textField: { size: "small" } }}
      />
    </LocalizationProvider>
  );
}
