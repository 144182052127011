import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { ServerDataGrid, usePagination } from "components/ui/ServerDataGrid";
import { NonOpticPosition, useListNonOpticPositionsQuery } from "features/trading/accounts";

const NonOpticPositionsList = () => {
  const [pagination, setPagination] = usePagination(25);
  const { data, isLoading, isFetching } = useListNonOpticPositionsQuery({ pagination });
  const columns: GridColDef<NonOpticPosition>[] = [
    {
      field: "name",
      headerName: "Name",
      width: 250,
    },
    {
      field: "symbol",
      headerName: "Symbol",
      width: 250,
      renderCell: ({ value }: GridRenderCellParams) => {
        return (
          <pre>{value}</pre>
        );
      },
    },
    {
      field: "client_name",
      headerName: "Client Name",
      width: 250,
    },
    {
      field: "account_number",
      headerName: "Account #",
      width: 150,
    },
    {
      field: "custodian_name",
      headerName: "Custodian",
      width: 150,
    },
    {
      field: "option_qty",
      headerName: "Option Qty",
      type: "number",
      width: 150,
    },
  ].map((colDef: GridColDef) => ({ ...colDef, filterable: false }));

  return (
    <ServerDataGrid
      slots={{}}
      rows={data?.results || []}
      rowCount={data?.count || 0}
      loading={isLoading || isFetching}
      columns={columns}
      density="compact"
      paginationModel={pagination}
      onPaginationModelChange={setPagination}
    />
  );
};

export default NonOpticPositionsList;
