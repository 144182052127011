import { Box, CircularProgress, MenuItem, Select } from "@mui/material";
import { useAppDispatch, useTrading } from "features/store";
import { useListAccountStrategiesQuery } from "features/trading/accounts";
import { setSelectedAccountStrategy } from "features/trading/tradingSlice";
import { useEffect } from "react";

export const AccountStrategyPicker = () => {
  const dispatch = useAppDispatch();
  const { currentAccountId, selectedAccountStrategy } = useTrading();

  useEffect(() => {
    dispatch(setSelectedAccountStrategy(undefined));
  }, [currentAccountId]);

  const { data, isLoading } = useListAccountStrategiesQuery(
    {
      filters: { account_id: currentAccountId },
    },
    {
      skip: !currentAccountId,
    },
  );

  if (isLoading || !data) {
    return <CircularProgress />;
  }

  const { results: strategies } = data;

  return (
    <Box display="flex" alignItems="center">
      <Select
        labelId="account-strategy-label"
        label="Strategy"
        size="small"
        value={selectedAccountStrategy ? selectedAccountStrategy.id : ""}
        sx={{ width: 250, borderRadius: 5 }}
        onChange={(event: any) => {
          const strategy = strategies.find(
            (strat) => strat.id === event.target.value,
          );
          dispatch(setSelectedAccountStrategy(strategy));
        }}
        disabled={strategies.length === 0}
      >
        {strategies.map((strat) => {
          return (
            <MenuItem key={strat.id} value={strat.id}>
              {strat.strategy__name}
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  );
};
