import {
  Box,
  Card,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useMemo, useRef, useState } from "react";
import { OptionGroup } from "./OptionGroup";
import { generateStrikeIntervals } from "helpers";
import { useAppDispatch, useFactsetStreaming, useTrading } from "features/store";
import { FDS_ActionTypes, FDS_SubscriptionTypes } from "constants/index";
import WebSocketWrapper from "ws/WebSocketWrapper";
import { addSubscription, cacheMessage, removeSubscription } from "features/factset-streaming/factsetStreamingSlice";

interface Props {
  isOpened: boolean;
  onClose: () => void;
}

export const OptionChainModal = ({ isOpened, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const { currentStrategy } = useTrading();
  const {
    subscriptions,
    dataCache: {
      [FDS_SubscriptionTypes.STOCK_PRICE]: stockPrices,
      [FDS_SubscriptionTypes.OPTION_CHAIN]: optionChain,
      [FDS_SubscriptionTypes.OPTION_CONTRACT]: optionContracts,
    }
  } = useFactsetStreaming();

  const [isOcLoading, setOcIsLoading] = useState<boolean>(false);
  const strikeRangeIntervals = generateStrikeIntervals(50);
  const [strikeRange, setStrikeRange] = useState<number>(10);
  const [openedOptionGroup, setOpenedOptionGroup] = useState<string | undefined>();


  const [selectedOption, setSelectedOption] = useState<
    any | undefined
  >();

  const stockPriceKey = useMemo(() => {
    if (!currentStrategy) {
      return null;
    }

    return FDS_SubscriptionTypes.STOCK_PRICE + ":" + currentStrategy.name; // add asset name to BE response
  }, [currentStrategy]);

  useEffect(() => {
    if (!stockPriceKey) {
      return;
    }

    const ws = new WebSocketWrapper();
    if (!(stockPriceKey in subscriptions)) {
      ws.connect(process.env.REACT_APP_FDS_URL! + "/fds");

      ws.on("open", (ev: any) => {
        if (stockPriceKey && !(stockPriceKey in subscriptions)) {
          const [_, symbol] = stockPriceKey.split(":");
          dispatch(addSubscription(stockPriceKey));
          ws.send(JSON.stringify({
            action: FDS_ActionTypes.SUBSCRIBE,
            type: FDS_SubscriptionTypes.STOCK_PRICE,
            symbol,
          }));
        }
      });

      ws.on("message", (ev: any) => {
        if (ev.data !== "ok") {
          dispatch(cacheMessage(JSON.parse(ev.data)));
        }
      });

      ws.on("close", (ev: any) => {
        if (stockPriceKey) {
          dispatch(removeSubscription(stockPriceKey));
        }
      });
    }

    return () => {
      if (stockPriceKey) {
        const [_, symbol] = stockPriceKey.split(":");
        dispatch(removeSubscription(stockPriceKey));
        ws.send(JSON.stringify({
          action: FDS_ActionTypes.UNSUBSCRIBE,
          type: FDS_SubscriptionTypes.STOCK_PRICE,
          symbol,
        }));
      }
    }
  }, [stockPriceKey]);

  const lastStockPriceRef = useRef<any>();

  const lastStockPrice = useMemo(() => {
    if (!currentStrategy) {
      return "N/A";
    }

    const stockSymbol = currentStrategy.name;

    if (!(stockSymbol in stockPrices)) {
      return "N/A";
    }

    return stockPrices[stockSymbol]?.last_price || "N/A";
  }, [currentStrategy, stockPrices]);

  const integerLastStockPrice = useMemo(() => {
    if (lastStockPrice === "N/A") {
      return "N/A";
    }

    return parseInt(lastStockPrice);
  }, [lastStockPrice]);

  useEffect(() => {
    if (!lastStockPriceRef.current || lastStockPriceRef.current === "N/A") {
      lastStockPriceRef.current = integerLastStockPrice
    }
  }, [integerLastStockPrice])

  const ocRequestParams = useMemo(() => {
    if (!lastStockPriceRef.current || lastStockPriceRef.current === "N/A") {
      return null;
    }

    let min = lastStockPriceRef.current - strikeRange;
    min = min < 0 ? 0 : min;
    const max = lastStockPriceRef.current + strikeRange;

    return `call&nearThreeMonths&strikes=${min}-${max}`;
  }, [lastStockPriceRef.current, strikeRange]);

  const ocKey = useMemo(() => {
    if (!currentStrategy || !ocRequestParams) {
      return null;
    }

    const stockSymbol = currentStrategy.name;

    return FDS_SubscriptionTypes.OPTION_CHAIN + ":" + stockSymbol + ocRequestParams;
  }, [currentStrategy, ocRequestParams]);

  useEffect(() => {
    if (!ocKey) {
      return;
    }

    setOcIsLoading(true);

    const ws = new WebSocketWrapper();

    ws.connect(process.env.REACT_APP_FDS_URL! + "/fds");

    ws.on("open", (ev: any) => {
      const symbol = currentStrategy!.name;
      ws.send(JSON.stringify({
        action: FDS_ActionTypes.GET,
        type: FDS_SubscriptionTypes.OPTION_CHAIN,
        symbol,
        requestParams: ocRequestParams,
      }));
    });

    ws.on("message", (ev: any) => {
      if (ev.data !== "ok")  {
        dispatch(cacheMessage(JSON.parse(ev.data)));
        ws.disconect();
        setOcIsLoading(false);
      }
    });
  }, [currentStrategy, ocKey]);

  const optionGroups = useMemo(() => {
    const keys = Object.keys(optionChain).filter((v) => v !== "streamer_key" && v !== "subscription_type");
    if (keys.length === 0) {
      return null;
    }

    const options = keys.map((key) => {
      const ocItem = optionChain[key];
      const contractItem = optionContracts[key];
      if (!contractItem) {
        return { ...ocItem };
      }
      return { ...ocItem, ...contractItem };
    });

    const groupedByExpDate = options.reduce((acc, option) => {
      const { expiration_date } = option;
      if (acc[expiration_date]) {
        acc[expiration_date].push(option);
      } else {
        acc[expiration_date] = [option];
      }

      return acc;
    }, {});

    const result = Object.keys(groupedByExpDate)
      .sort()
      .map((expDate: string) => {
        return {
          expirationDate: expDate,
          options: groupedByExpDate[expDate].sort((a: any, b: any) => {
            return parseFloat(a.strike_price) - parseFloat(b.strike_price);
          }),
        };
      });
    return result;
  }, [optionChain, optionContracts]);

  return (
    <Modal open={isOpened} onClose={onClose}>
      <Stack height="100%" alignItems="center" justifyContent="center">
        <Box component="div" sx={{ width: "1200px" }}>
          <Card>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h5" component="h5" sx={{ padding: 1 }}>
                  Option Chain
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              <Grid item>
                {currentStrategy && (
                  <Typography sx={{ padding: 1 }}>
                    Stock Price: {
                      lastStockPrice !== "N/A"
                      ? Number(lastStockPrice).toFixed(2) + "$"
                      : lastStockPrice
                    }
                  </Typography>
                )}
              </Grid>
              <Divider orientation="vertical" flexItem sx={{ marginX: 1 }} />
              <Grid>
                <Grid container sx={{ padding: 1 }} alignItems="center">
                  <Grid item>
                    <Typography>Strike Range +/-</Typography>
                  </Grid>
                  <Grid item sx={{ paddingLeft: 1 }}>
                    <Select
                      value={strikeRange}
                      onChange={(e) => setStrikeRange(Number(e.target.value))}
                    >
                      {strikeRangeIntervals.map((interval: number) => (
                        <MenuItem key={interval} value={interval}>
                          {interval}$
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
              <Divider orientation="vertical" flexItem sx={{ marginX: 1 }} />
              <Grid>
                <Typography sx={{ padding: 1 }}>
                  Expiration Date Range: Near Three Month
                </Typography>
              </Grid>
            </Grid>
            <Grid sx={{ height: "800px", overflowY: "auto" }}>
              {isOcLoading && <CircularProgress />}
              {!isOcLoading && optionGroups &&
                optionGroups.map((optGroup: any) => (
                  <OptionGroup
                    expirationDate={optGroup.expirationDate}
                    options={optGroup.options}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    closeModal={onClose}
                    opened={optGroup.expirationDate === openedOptionGroup}
                    onOpen={() => setOpenedOptionGroup(optGroup.expirationDate)}
                    onClose={() => setOpenedOptionGroup(undefined)}
                  />
                ))}
            </Grid>
          </Card>
        </Box>
      </Stack>
    </Modal>
  );
};
