import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./containers/HomePage";
import LoginPage from "./containers/LoginPage";
import ClientsPage from "./containers/ClientsPage";
import { CssBaseline } from "@mui/material";
import { useCheckAuthMutation, useGetUserQuery } from "features/auth/auth";
import { useEffect } from "react";
import StockDetailPage from "containers/StockDetailPage";
import UnderlyingCompositePage from "containers/UnderlyingCompositePage";
import AccountsPage from "containers/AccountsPage";
import { AlertsPage } from "containers/AlertsPage";
import { OrdersPage } from "containers/OrderPage";
import NonOpticPage from "containers/NonOpticPage";
import UserAlertsWrapper from "components/UserAlertsWrapper";

function App() {
  const [checkAuth] = useCheckAuthMutation();
  useGetUserQuery();
  useEffect(() => {
    checkAuth();
}, [])  

  return (
    <>
      <CssBaseline />
      <BrowserRouter>
        <UserAlertsWrapper />
        <Routes>
          <Route path="" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/clients" element={<ClientsPage />} />
          <Route path="/accounts" element={<AccountsPage />} />
          <Route path="/stock" element={<StockDetailPage />} />
          <Route path="/alerts" element={<AlertsPage />} />
          <Route path="/composite" element={<UnderlyingCompositePage />} />
          <Route path="/orders" element={<OrdersPage />} />
          <Route path="/non_optic" element={<NonOpticPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
