import { MenuItem, Select, SelectProps } from "@mui/material";

export function YesNoSelect(props: SelectProps) {
  return (
    <Select
      {...props}
      value={props.value ? 1 : 0}
      sx={{ width: 100, borderRadius: 5 }}
      size="small"
    >
      <MenuItem value={1}>Yes</MenuItem>
      <MenuItem value={0}>No</MenuItem>
    </Select>
  );
}
