import { StrategyGroupPicker } from "components/holdings/StrategyGroupPicker";
import Layout from "../components/Layout";
import { Box, Card, IconButton, Tooltip } from "@mui/material";
import { StrategyPicker } from "components/holdings/StrategyPicker";
import StrategyPositionsList from "components/holdings/StrategyPositionsList";
import SettlementDatePicker from "components/holdings/SettlementDatePicker";
import { StrategyTradeSetup } from "components/holdings/StrategyTradeSetup";
import { useCallback } from "react";
import SyncEnabledIcon from "@mui/icons-material/Sync";
import SyncDisabledIcon from "@mui/icons-material/SyncDisabled";
import { useAppDispatch, useTrading } from "features/store";
import { setPositionsPollingInterval } from "features/trading/tradingSlice";
import { DEFAULT_POLLING_INTERVAL, StrategyGroupEnum } from "constants/index";
import { NotIncludedAccounts } from "components/holdings/NotIncludedAccounts";
import { FillWithAccountsButton } from "components/holdings/FillWithAccountsButton";

export default function HomePage() {
  const dispatch = useAppDispatch();
  const { positionsPollingInterval } = useTrading();
  const togglePolling = useCallback(() => {
    dispatch(setPositionsPollingInterval(
      positionsPollingInterval === DEFAULT_POLLING_INTERVAL ? 0 : DEFAULT_POLLING_INTERVAL
    ));
  }, [dispatch, positionsPollingInterval, setPositionsPollingInterval]);
  const { currentStrategy } = useTrading();

  return (
    <Layout title="Strategies" content="Strategies">
      <Card>
        <Box display={"flex"} flexDirection={"row"} alignContent={"center"}>
          <StrategyGroupPicker />
          <StrategyPicker />
          {currentStrategy &&
            currentStrategy?.strategy_group != StrategyGroupEnum.STANDARD && (
              <FillWithAccountsButton />
            )}
          <Box flexGrow={1} />
          <Tooltip title={`${positionsPollingInterval === DEFAULT_POLLING_INTERVAL ? "Disable": "Enable"} auto refresh`}>
            <IconButton onClick={togglePolling} sx={{ marginX: 1 }}>
              {positionsPollingInterval ? (<SyncEnabledIcon />) : (<SyncDisabledIcon />)}
            </IconButton>
          </Tooltip>
          <SettlementDatePicker />
        </Box>
        <StrategyTradeSetup />
        <StrategyPositionsList />
        <NotIncludedAccounts />
      </Card>
    </Layout>
  );
}
