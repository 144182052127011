import { Tune } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
} from "@mui/material";
import {
  GridColDef,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import Layout from "components/Layout";
import StockPicker from "components/holdings/StockPicker";
import {
  useListUserAlertsQuery,
  useUpdateUserAlertMutation,
} from "features/trading/accounts";
import { useAuth } from "features/store";
import dayjs from "dayjs";
import { ServerDataGrid, usePagination } from "components/ui/ServerDataGrid";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setCurrentStrategy, setCurrentStrategyGroup } from "features/trading/tradingSlice";

const StatusChipColor: any = {
  Pending: "default",
  Traded: "success",
  Ignored: "error",
};

const wrapWithLayout = (child: JSX.Element) => {
  return (
    <Layout title="Alerts" content="Alerts page">
      {child}
    </Layout>
  );
};

export const AlertsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, user } = useAuth();
  const [pagination, setPagination] = usePagination(10);
  const { data, isLoading } = useListUserAlertsQuery(
    {
      pagination,
      filters: {
        user: user?.id,
        alert__type: "Position Mismatch",
      },
    },
    {
      skip: !isAuthenticated && !user,
      skipPollingIfUnfocused: true,
      pollingInterval: 15_000,
    },
  );

  const [updateUserAlert] = useUpdateUserAlertMutation();

  const markAsSeen = useCallback((id: number) => {
    updateUserAlert({ id, seen: true });
  }, [updateUserAlert]);

  const onCheckClick = useCallback((userAlertId: number, strategyId: number, groupId: number, seen: boolean = false) => {
    if (!seen) {
      markAsSeen(userAlertId);
    }
    dispatch(setCurrentStrategy({ id: strategyId, strategy_group: groupId } as any));
    dispatch(setCurrentStrategyGroup(groupId));
    navigate("/stock");
  }, [dispatch, setCurrentStrategy, markAsSeen, navigate]);

  const columns: GridColDef[] = [
    {
      field: "created_on",
      headerName: "Date & Time",
      valueFormatter: ({ value }: GridValueFormatterParams) =>
        dayjs(value).format("MM/DD/YYYY hh:mm:ss A"),
      minWidth: 200,
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 150,
    },
    {
      field: "payload",
      headerName: "Description",
      renderCell: ({ row }) => (
        <Grid sx={{ margin: 1 }} style={{ whiteSpace: "pre-line" }}>{row.payload.body}</Grid>
      ),
      minWidth: 100,
      flex: 2,
    },
    {
      field: "action",
      headerName: "Action Recommended",
      minWidth: 100,
      flex: 2,
      renderCell: (params) => {
        const {
          row: { id, seen, payload },
        } = params;
          const { strategy_id, group_id } = payload;
          return (
            <Grid sx={{ margin: 1 }}>
              <Button
                variant="outlined"
                color="info"
                onClick={() => onCheckClick(id, strategy_id, group_id, seen)}
              >
                Check
              </Button>
              <Button
                variant="outlined"
                color="info"
                sx={{ marginLeft: 2 }}
                onClick={() => markAsSeen(id)}
                disabled={seen}
              >
                Mark as seen
              </Button>
            </Grid>
          );
      },
    },
  ];

  if (isLoading || !data) {
    return wrapWithLayout(<CircularProgress />);
  }

  return wrapWithLayout(
    <Box margin={2}>
      <Grid container>
        <Grid item xs={3}>
          <StockPicker />
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={3}>
          <IconButton size="small">
            <Tune />
          </IconButton>
          <Button variant="contained" size="small" sx={{ ml: 1 }}>
            All
          </Button>
          <Button variant="outlined" size="small" sx={{ ml: 1 }}>
            Unread
          </Button>
        </Grid>
      </Grid>

      <ServerDataGrid
        columns={columns}
        rows={data?.results}
        rowCount={data?.count}
        density="comfortable"
        getRowHeight={() => "auto"}
        paginationModel={pagination}
        onPaginationModelChange={setPagination}
      />
    </Box>,
  );
};
