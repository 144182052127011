import { ThemeProvider } from "@mui/material/styles";
import { Box } from "@mui/material";
import { theme } from "./hoc/Theme";
import { usePageTitle } from "./hoc/Hooks";
import Header from "./Header";
import { APP_NAME } from "config";
import SideMenu from "./SideMenu";
import { useLayout } from "features/store";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

type LayoutProps = {
  title?: string;
  content?: string;
};

const Layout = ({
  title,
  content,
  children,
}: React.PropsWithChildren<LayoutProps>) => {
  usePageTitle(`${APP_NAME} | ` + (title || ""));

  const { drawerWidth } = useLayout();

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box width={"100%"}>
          <Box
            marginLeft={`${drawerWidth + 8}px`}
            marginTop={"75px"}
            marginRight={1}
            flexGrow={1}
          >
            {children}
          </Box>
        </Box>
        <SideMenu />
        <Header title={title} />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default Layout;
