import {
  Button,
  Card,
  List,
  ListItem,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useTrading } from "features/store";
import { DraftOrder, TradeMode } from "features/trading/tradingSlice";
import { useCallback, useMemo } from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  useNewOrdersBulkMutation,
  useProcessOrdersMutation,
} from "features/trading/accounts";
import { getTradeModeLabel } from "helpers";
import { useSnackbar } from "notistack";
import { NOTISTACK_FAILURE_OPTIONS, NOTISTACK_SUCCESS_OPTIONS } from "constants/index";

interface Props {
  isOpened: boolean;
  onClose: () => void;
}

interface CustodiansSummary {
  [key: string]: number;
}

const renderSummaryBlock = (title: string, summary: CustodiansSummary) => {
  return (
    <>
      <Typography sx={{ paddingTop: 1, paddingX: 2 }}>{title}</Typography>
      <List>
        {Object.entries(summary).map((custodianChanges) => {
          const [custodian_name, quantity] = custodianChanges;
          return (
            <ListItem>
              <Typography>{`${custodian_name} - ${quantity}`}</Typography>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export const TradeConfirmationModal = ({ isOpened, onClose }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { tradeMode, draftOrders, currentStrategy } = useTrading();
  const [newOrdersBulk] = useNewOrdersBulkMutation();
  const [processOrders] = useProcessOrdersMutation();
  const custodiansSummary: CustodiansSummary[] = useMemo(() => {
    if (!draftOrders) {
      return [{}, {}];
    }

    return draftOrders.reduce(
      (acc: CustodiansSummary[], order: DraftOrder) => {
        const [open, close] = acc;
        const { custodian_name, quantity } = order;
        const positionsAcc = quantity < 0 ? open : close;
        if (positionsAcc[custodian_name]) {
          positionsAcc[custodian_name] += quantity;
        } else {
          positionsAcc[custodian_name] = quantity;
        }

        return [open, close];
      },
      [{}, {}],
    ); // first for open positions, second for closed
  }, [draftOrders]);

  const renderSummary = useCallback(() => {
    const [open, close] = custodiansSummary;

    if (tradeMode === TradeMode.CallRebalance) {
      return (
        <>
          {JSON.stringify(open) !== "{}" &&
            renderSummaryBlock(
              "Positions to open in following custodians",
              open,
            )}
          {JSON.stringify(close) !== "{}" &&
            renderSummaryBlock(
              "Positions to close in following custodiaons",
              close,
            )}
        </>
      );
    }

    return renderSummaryBlock(
      `You are going to ${tradeMode === TradeMode.CallSellShort ? "open new" : "close"} positions in following custodians`,
      tradeMode === TradeMode.CallSellShort ? open : close,
    );
  }, [custodiansSummary, tradeMode, renderSummaryBlock]);

  const handleConfirmClick = () => {
    if (draftOrders !== undefined) {
      const normalizedOrders = draftOrders
        .filter((draftOrder: DraftOrder) => draftOrder.quantity !== 0)
        .map((draftOrder: DraftOrder) => ({
          ...draftOrder,
          quantity: Math.abs(draftOrder.quantity),
        }));

      newOrdersBulk(normalizedOrders).then((res) => {
        if ("error" in res) {
          enqueueSnackbar("Orders creation has failed.", NOTISTACK_FAILURE_OPTIONS as any);
          return Promise.reject();
        }

        enqueueSnackbar("Orders were created!", NOTISTACK_SUCCESS_OPTIONS as any);

        return Promise.resolve();
      }).then(() => {
        return processOrders({ asset_id: currentStrategy?.current_call! });
      }).then((res) => {
        if ("error" in res) {
          enqueueSnackbar("Orders staging has failed.", NOTISTACK_FAILURE_OPTIONS as any);
        } else {
          enqueueSnackbar("Orders were staged!", NOTISTACK_SUCCESS_OPTIONS as any);
        }
      }).catch(() => {});
    }
    onClose();
  };

  return (
    <Modal open={isOpened} onClose={onClose}>
      <Stack height="100%" alignItems="center" justifyContent="center">
        <Box style={{ width: "600px" }}>
          <Card>
            <Typography
              variant="h5"
              component="h5"
              align="center"
              sx={{ paddingTop: 1 }}
            >
              {`Trade Confirmation (${getTradeModeLabel(tradeMode)})`}
            </Typography>
            {renderSummary()}
            <Box display="flex" justifyContent="center">
              <Typography>Please confirm your trade order</Typography>
            </Box>

            <Box display="flex">
              <Button
                startIcon={<CloseIcon />}
                fullWidth
                sx={{ marginX: 4, marginY: 1 }}
                color="error"
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                startIcon={<CheckIcon />}
                fullWidth
                sx={{ marginX: 4, marginY: 1 }}
                color="primary"
                onClick={handleConfirmClick}
              >
                Confirm
              </Button>
            </Box>
          </Card>
        </Box>
      </Stack>
    </Modal>
  );
};
