import { OrdersList } from "components/clients/OrdersList";
import Layout from "components/Layout"

export const OrdersPage = () => {
  return (
    <Layout title="Orders" content="Orders page">
      <OrdersList />
    </Layout>
  );
};
