import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ServerDataGrid,
  qtyFormatterWhole,
} from "components/ui/ServerDataGrid";
import { useAppDispatch, useTrading } from "features/store";
import {
  NotIncludedAccount,
  useListNotIncludedAccountsQuery,
} from "features/trading/accounts";
import { useMemo, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { GridColDef } from "@mui/x-data-grid";
import { usePagination } from "components/ui/ServerDataGrid";
import AddIcon from "@mui/icons-material/Add";
import { AddAccountStrategyModal } from "./AddAccountStrategyModal";
import { setCurrentAccount } from "features/trading/tradingSlice";

export const NotIncludedAccounts = () => {
  const dispatch = useAppDispatch();
  const { currentStrategy, currentAccountId } = useTrading();
  const [pagination, setPagination] = usePagination();
  const { data, isLoading, isFetching } = useListNotIncludedAccountsQuery(
    {
      id: currentStrategy?.id || 0,
      pagination,
    },
    { skip: !currentStrategy?.id },
  );

  const accounts = useMemo(() => {
    if (isLoading || isFetching || !data) {
      return [];
    }

    const { results } = data;
    return results;
  }, [data, isLoading]);

  const [isOpenedAddModal, setIsOpenAddModal] = useState<boolean>(false);
  const openAddModal = (id: number) => {
    dispatch(setCurrentAccount(id));
    setIsOpenAddModal(true);
  };
  const onAddModalClose = () => {
    setIsOpenAddModal(false);
    dispatch(setCurrentAccount(null));
  };

  const columns: GridColDef<NotIncludedAccount>[] = [
    {
      field: "client__name",
      headerName: "Client Name",
      width: 250,
    },
    {
      field: "account_number",
      headerName: "Account #",
      width: 150,
      filterable: false,
    },
    {
      field: "custodian__name",
      headerName: "Custodian",
      width: 150,
      filterable: false,
    },
    {
      field: "stock_qty",
      headerName: "Stock Qty",
      filterable: false,
      type: "number",
      renderCell: qtyFormatterWhole,
    },
    {
      field: "action",
      headerName: "Actions",
      minWidth: 100,
      renderCell: (params) => {
        const {
          row: { id },
        } = params;

        return (
          <Tooltip title="Add account to strategy">
            <IconButton onClick={() => openAddModal(id)}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
          <Typography variant="h6">
            Not included accounts with stocks
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ServerDataGrid
            slots={{}}
            rows={accounts}
            rowCount={data?.count || 0}
            loading={isLoading || isFetching}
            columns={columns}
            density="compact"
            paginationModel={pagination}
            onPaginationModelChange={setPagination}
          />
        </AccordionDetails>
      </Accordion>
      <AddAccountStrategyModal
        isOpened={isOpenedAddModal}
        onClose={onAddModalClose}
        accountId={currentAccountId!}
        strategy={currentStrategy}
      />
    </>
  );
};
