import { createSlice } from "@reduxjs/toolkit";
import { Strategy, apiWithTrading, AccountStrategy } from "./accounts";
import dayjs from "dayjs";
import { DEFAULT_LIMIT_PRICE, DEFAULT_POLLING_INTERVAL, OrderType } from "constants/index";
import { updateLastStrategyGroupId, updateLastStrategy } from "helpers";

export enum TradeMode {
  CallSellShort,
  CallBuyToCover,
  CallRebalance
}

export interface DraftOrder {
  account: number;
  asset: number;
  quantity: number;
  type: string;
  price: number | null;
  side: string;
  custodian_name: string;
}


export interface TradingState {
  currentClientId?: number;
  currentAccountId?: number;
  currentSettlementDate?: string;
  currentStrategyGroupId?: number;
  currentStrategy?: Strategy;
  selectedAccountStrategy?: AccountStrategy;
  tradeMode: TradeMode;
  lastSettlementDate?: string;
  settlementDates?: Record<string, string>;
  draftOrders?: DraftOrder[];
  positionsPollingInterval: number;
  orderType: OrderType;
  limitPrice: number;
}

const initialState: TradingState = {
  tradeMode: TradeMode.CallSellShort,
  draftOrders: [],
  positionsPollingInterval: DEFAULT_POLLING_INTERVAL,
  orderType: OrderType.MARKET,
  limitPrice: DEFAULT_LIMIT_PRICE,
};

const tradingSlice = createSlice({
  name: "trading",
  initialState,
  reducers: {
    setCurrentClient: (state, action) => {
      state.currentClientId = action.payload;
    },
    setCurrentAccount: (state, action) => {
      state.currentAccountId = action.payload;
    },
    setCurrentSettlementDate: (state, action) => {
      state.currentSettlementDate = action.payload;
    },
    setCurrentStrategyGroup: (state, action) => {
      state.currentStrategyGroupId = action.payload;
      updateLastStrategyGroupId(action.payload);
    },
    setCurrentStrategy: (state, action) => {
      state.currentStrategy = action.payload;
      updateLastStrategy(action.payload);
    },
    setSelectedAccountStrategy: (state, action) => {
      state.selectedAccountStrategy = action.payload;
    },
    setTradeMode: (state, action) => {
      state.tradeMode = action.payload;
    },
    setDraftOrders: (state, action) => {
      state.draftOrders = action.payload;
    },
    setPositionsPollingInterval: (state, action) => {
      state.positionsPollingInterval = action.payload;
    },
    setOrderType: (state, action) => {
      state.orderType = action.payload;
    },
    setLimitPrice: (state, action) => {
      state.limitPrice = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      apiWithTrading.endpoints.listSettlementDates.matchFulfilled,
      (state, action) => {
        let latestSettlementDate: Date | undefined;
        const settlementDates = action.payload.results.reduce(
          (acc: Record<string, string>, result) => {
            const date = dayjs(result.date).toDate();
            if (!latestSettlementDate || date > latestSettlementDate) {
              latestSettlementDate = date;
            }
            acc[dayjs(date).format("YYYY-MM-DD")] = result.date;
            return acc;
          },
          {},
        );
        state.settlementDates = settlementDates;
        state.lastSettlementDate = latestSettlementDate?.toISOString();

        if (state.currentSettlementDate === undefined) {
          state.currentSettlementDate = dayjs(latestSettlementDate || null).format("YYYY-MM-DD");
        }
      },
    )
  }
});

export default tradingSlice.reducer;
export const {
  setCurrentAccount,
  setCurrentClient,
  setCurrentSettlementDate,
  setCurrentStrategy,
  setSelectedAccountStrategy,
  setCurrentStrategyGroup,
  setTradeMode,
  setDraftOrders,
  setPositionsPollingInterval,
  setOrderType,
  setLimitPrice,
} = tradingSlice.actions;
