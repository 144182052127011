import { CircularProgress } from "@mui/material";
import {
  GridColDef,
  GridFilterModel,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { ServerDataGrid } from "components/ui/ServerDataGrid";
import { OrderStatus } from "constants/index";
import dayjs from "dayjs";
import { useListOrdersQuery } from "features/trading/accounts";
import { useState } from "react";

export const OrdersList = () => {
  const [gridFiltersState, setGridFiltersState] = useState<GridFilterModel>();
  const { data, isLoading } = useListOrdersQuery({
    gridFilters: gridFiltersState,
  });

  if (isLoading || !data) {
    return <CircularProgress />;
  }

  const columns: GridColDef[] = [
    {
      field: "created_on",
      headerName: "Created At",
      valueFormatter: ({ value }: GridValueFormatterParams) =>
        dayjs(value).format("MM/DD/YYYY hh:mm:ss A"),
      minWidth: 200,
    },
    {
      field: "ext_order_id",
      headerName: "Ext. ID",
      width: 200,
      filterable: false,
    },
    {
      field: "asset__symbol",
      headerName: "Symbol",
      width: 220,
      filterable: false,
    },
    {
      field: "client__name",
      headerName: "Client Name",
      width: 250,
      filterable: false,
    },
    {
      field: "account__number",
      headerName: "Account #",
      width: 150,
      filterable: false,
    },
    {
      field: "custodian__name",
      headerName: "Custodian",
      width: 150,
      filterable: false,
    },
    {
      field: "filled_price",
      headerName: "Filled price",
      filterable: false,
    },
    {
      field: "price",
      headerName: "Price",
      filterable: false,
    },
    {
      field: "confirmed_quantity",
      headerName: "Confirmed qty",
      width: 150,
      filterable: false,
    },
    {
      field: "filled_qty",
      headerName: "Filled qty",
      filterable: false,
    },
    {
      field: "quantity",
      headerName: "Qty",
      filterable: false,
    },
    {
      field: "type",
      headerName: "Type",
      filterable: false,
    },
    {
      field: "side",
      headerName: "Side",
      filterable: false,
    },
    {
      field: "status",
      headerName: "Status",
      type: "singleSelect",
      valueOptions: () => Object.values(OrderStatus),
    },
    {
      field: "status_message",
      headerName: "Status message",
      width: 150,
      filterable: false,
    },
  ].map((col) => ({
    ...col,
    valueFormatter: col.valueFormatter
      ? col.valueFormatter
      : ({ value }: GridValueFormatterParams) =>
          value === null ? "N/A" : value,
  }));

  const { results: orders, count } = data;

  return (
    <ServerDataGrid
      columns={columns}
      rows={orders}
      rowCount={count}
      onFilterModelChange={(filters: GridFilterModel) =>
        setGridFiltersState(filters)
      }
    />
  );
};
