import { Box } from "@mui/material";
import Layout from "../components/Layout";
import AccountsList from "components/clients/AccountsList";

export default function HomePage() {
  return (
    <Layout title="Accounts" content="Accounts page">
      <Box>
        <AccountsList />
      </Box>
    </Layout>
  );
}
