import { useNavigate } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Notifications, Search, WavingHand } from "@mui/icons-material";

import { useAlerts, useAuth, useLayout } from "features/store";

import { useEffect, useMemo } from "react";
import { Badge, IconButton, Link, Input, InputAdornment } from "@mui/material";

interface HeaderProps {
  title?: string;
}

const Header = ({ title }: HeaderProps) => {
  const { user, isAuthenticated, isLoading } = useAuth();
  const { unseenCount } = useAlerts();
  const navigate = useNavigate();

  const { drawerWidth } = useLayout();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      navigate("/login");
    }
  }, [isAuthenticated, isLoading, navigate]);

  const alertsIcon = useMemo(() => {
    const el = <Notifications />;
    if (unseenCount) {
      return (
        <Badge badgeContent={unseenCount} color="error">
          {el}
        </Badge>
      );
    }

    return el;
  }, [unseenCount]);

  return (
    <AppBar>
      <Toolbar>
        <Typography
          color="inherit"
          noWrap
          variant="h5"
          marginLeft={`${drawerWidth}px`}
        >
          {title == "Home" ? (
            <>
              {`Hello ${user ? user.first_name : ""}`}{" "}
              <WavingHand sx={{ color: "orange" }} />
            </>
          ) : (
            title
          )}
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          flexGrow={1}
        >
          <Link href="/alerts">
            <IconButton size="large" color="inherit">
              {alertsIcon}
            </IconButton>
          </Link>
          <Input
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
            placeholder="Search"
            sx={{ width: "200px" }}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
