import { createSlice } from "@reduxjs/toolkit";
import { FDS_SubscriptionTypes } from "constants/index";

export interface FactsetStreamingState {
  subscriptions: { [key: string]: string },
  dataCache: {
    [FDS_SubscriptionTypes.HOMEPAGE_STOCK]: {
      [key: string]: any
    },
    [FDS_SubscriptionTypes.HOMEPAGE_OPTION]: {
      [key: string]: any
    },
    [FDS_SubscriptionTypes.OPTION_CONTRACT]: {
      [key: string]: any
    }
    [FDS_SubscriptionTypes.OPTION_CHAIN]: {
      [key: string]: any
    },
    [FDS_SubscriptionTypes.STOCK_PRICE]: {
      [key: string]: any
    }
  }
}

const initialState: FactsetStreamingState = {
  subscriptions: {},
  dataCache: {
    [FDS_SubscriptionTypes.HOMEPAGE_STOCK]: {},
    [FDS_SubscriptionTypes.HOMEPAGE_OPTION]: {},
    [FDS_SubscriptionTypes.OPTION_CONTRACT]: {},
    [FDS_SubscriptionTypes.OPTION_CHAIN]: {},
    [FDS_SubscriptionTypes.STOCK_PRICE]: {}
  }
}

const factsetStreamingSlice = createSlice({
  name: "factset-streaming",
  initialState,
  reducers: {
    addSubscription: (state, action) => {
      state.subscriptions[action.payload] = action.payload;
    },
    removeSubscription: (state, action) => {
      delete state.subscriptions[action.payload]
    },
    cacheMessage: (state, action) => {
      const subType: FDS_SubscriptionTypes = action.payload.subscription_type;
      state.dataCache[subType] = action.payload as any;
    },
    clearCache: (state, _) => {
      state.dataCache = { ...initialState.dataCache };
    },
  }
});

export default factsetStreamingSlice.reducer;
export const {
  addSubscription,
  removeSubscription,
  cacheMessage,
  clearCache,
} = factsetStreamingSlice.actions;
