import { Box, CircularProgress, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts";
import { asCurrency } from "components/ui/formatters";
import { useGetStatsQuery } from "features/trading/accounts";

export const PlChart = () => {
  const { data, isLoading } = useGetStatsQuery();

  if (!isLoading && data && data.results?.length > 0) {
    return (
      <Box>
        <Typography variant="h5">
          P/L:{" "}
          {asCurrency(
            data.results[data.results.length - 1].total_unrealized_gain_loss,
          )}
          M
        </Typography>

        <BarChart
          dataset={data.results as any}
          series={[{ dataKey: "total_unrealized_gain_loss" }]}
          height={290}
          xAxis={[
            {
              dataKey: "as_of_date",
              scaleType: "band",
            },
          ]}
          sx={{ width: 100, height: 100 }}
        />
      </Box>
    );
  } else {
    return <CircularProgress />;
  }
};
