import { Grid, IconButton, LinearProgress, Tooltip } from "@mui/material";
import { GridColDef, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter } from "@mui/x-data-grid";
import {
  ServerDataGrid,
  useGridFilters,
  usePagination,
  useSorting,
} from "components/ui/ServerDataGrid";
import { useAppDispatch, useAuth, useTrading } from "features/store";
import { useListAccountsQuery, Account, useRemoveAccountMutation } from "features/trading/accounts";
import { setCurrentAccount } from "features/trading/tradingSlice";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { AccountsModal } from "./AccountsModal";
import TrashIcon from "@mui/icons-material/Delete";
import { FormMode } from "constants/index";
import EditIcon from "@mui/icons-material/Edit";


interface AccountsListProps {
  clientId?: number;
}

interface ToolBarProps {
  openModal: () => void;
  showSearch: boolean;
}

const ToolBar = ({ openModal, showSearch = true }: ToolBarProps) => {
  return (
    <GridToolbarContainer>
      <Grid container justifyContent="space-between">
        <Grid item>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
          <Tooltip title="Add new account">
            <IconButton onClick={openModal}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          {showSearch && <GridToolbarQuickFilter sx={{ width: 200 }} />}
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
};

export default function AccountsList({ clientId }: AccountsListProps) {
  const { isAuthenticated } = useAuth();
  const dispatch = useAppDispatch();
  const { currentAccountId } = useTrading();

  const [sorting, setSorting] = useSorting("client__name");
  const [pagination, setPagination] = usePagination();
  const [gridFilters, setGridFilters] = useGridFilters();

  const [removeAccount] = useRemoveAccountMutation();

  const { data: accounts, isLoading } = useListAccountsQuery(
    {
      pagination,
      sorting,
      gridFilters,
      ...{ filters: clientId ? { client: clientId } : undefined },
    },
    { skip: !isAuthenticated },
  );

  useEffect(() => {
    if (
      accounts &&
      accounts.results.length > 0 &&
      (!currentAccountId ||
        !accounts.results.some((account) => account.id === currentAccountId))
    ) {
      dispatch(setCurrentAccount(accounts.results[0].id));
    }
  }, [accounts, currentAccountId, dispatch, setCurrentAccount]);

  const [isOpenedModal, setIsOpenedModal] = useState<boolean>(false);
  const [modalFormMode, setModalFormMode] = useState<FormMode>(FormMode.CREATE);
  const [account, setAccount] = useState<Account | undefined>();
  const openModal = (mode: FormMode = FormMode.CREATE, account: Account | undefined = undefined) =>  {
    setModalFormMode(mode);
    setAccount(mode == FormMode.EDIT ? account : undefined);
    setIsOpenedModal(true)
  };

  const closeModal = () => setIsOpenedModal(false);

  const defaultSlots = { toolbar: ToolBar };
  const slots = clientId ? { slots: {...defaultSlots, loadingOverlay: LinearProgress } } : { slots: defaultSlots };

  const columns: GridColDef<Account>[] = [
    { field: "id", headerName: "ID", width: 90, filterable: false },
    { field: "client", headerName: "Client ID", width: 150 },
    {
      field: "orion_account_id",
      headerName: "Orion Account ID",
      width: 150,
      filterable: false,
    },
    { field: "client__name", headerName: "Client Name", width: 150 },
    {
      field: "account_number",
      headerName: "Account #",
      width: 150,
      filterable: false,
    },
    { field: "reg_type__name", headerName: "Account Type", width: 150 },
    {
      field: "custodian__name",
      headerName: "Custodian",
      width: 150,
      filterable: false,
    },
    {
      field: "client__representative__name",
      headerName: "Representative",
      width: 150,
      filterable: false,
    },
    {
      field: "action",
      headerName: "Actions",
      minWidth: 150,
      renderCell: (params) => {
        const { row } = params;
        const { id, orion_account_id } = row;
        return (
          <Grid sx={{ margin: 1}}>
            <Tooltip title="Edit account">
              <IconButton onClick={() => openModal(FormMode.EDIT, row)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Remove account">
              <IconButton onClick={() => removeAccount(id)} disabled={orion_account_id > 0}>
                <TrashIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )
      }
    },
  ];

  return (
    <>
      <ServerDataGrid
        {...slots}
        slotProps={{ toolbar: { openModal, showSearch: !clientId } }}
        rows={accounts?.results || []}
        rowCount={accounts?.count || 0}
        loading={isLoading}
        columns={columns}
        paginationModel={pagination}
        sortModel={sorting}
        filterModel={gridFilters}
        onSortModelChange={setSorting}
        onFilterModelChange={setGridFilters}
        onPaginationModelChange={setPagination}
        rowSelectionModel={currentAccountId ? [currentAccountId] : []}
        onRowSelectionModelChange={(selectedRows) => {
          if (selectedRows.length && selectedRows[0] !== currentAccountId) {
            dispatch(setCurrentAccount(selectedRows[0] || null));
          }
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
              client: false,
              client__name: !clientId,
              orion_account_id: false,
            },
          },
        }}
      />
      <AccountsModal isOpened={isOpenedModal} onClose={closeModal} mode={modalFormMode} account={account} />
    </>
  );
}
