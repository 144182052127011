import { AumChart } from "components/stats/AUM";
import Layout from "../components/Layout";
import { Card, Container, Grid, Typography } from "@mui/material";
import { PlChart } from "components/stats/PL";
import { StrategyGroupsList } from "components/clients/StrategyGroupsList";

export default function HomePage() {
  return (
    <Layout title="Home" content="Home page">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StrategyGroupsList />
        </Grid>
        <Grid item xs={6}>
          <Card>
            <AumChart />
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card>
            <PlChart />
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
}
