import { CircularProgress, Grid, Typography } from "@mui/material";
import { useGetClientQuery } from "features/trading/accounts";

export default function ClientProfile({ clientId }: { clientId?: number }) {
  const { data: client, isLoading } = useGetClientQuery(clientId, { skip: !clientId });
  
  const profile = client ? [
    { name: "Optic ID", value: clientId },
    { name: "Orion ID", value: clientId },
    { name: "Representative", value: <Typography>{client.representative__name}</Typography> },
  ] : undefined;

  return profile ? (
    <Grid container spacing={2}>
      {profile.map((item, index) => (
        <Grid item container spacing={2} key={index}>
          <Grid item xs>
            <Typography>{item.name}</Typography>
          </Grid>
          <Grid item xs={10}>
            {item.value}
          </Grid>
        </Grid>
      ))}
    </Grid>
  ) : <CircularProgress />;
}
