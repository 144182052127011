import { API_URL } from "config";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "./store";
import {
  GridFilterModel,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";

export const DEFAULT_PAGE_SIZE = 10;

export interface ListResponse<T> {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
}

export interface GridModel {
  pagination?: GridPaginationModel;
  sorting?: GridSortModel;
  gridFilters?: GridFilterModel;
  filters?: Record<string, any>;
}

export const gridModelsToQuery = (
  gridModel: GridModel,
): Record<string, any> | undefined => {
  let query = {};
  const { pagination, sorting, gridFilters: gridFilters, filters } = gridModel;

  if (pagination) {
    query = {
      ...query,
      page: (pagination.page || 0) + 1,
      page_size: pagination.pageSize || DEFAULT_PAGE_SIZE,
    };
  }

  if (sorting && sorting.length) {
    query = {
      ...query,
      ordering: `${sorting[0].sort == "asc" ? "" : "-"}${sorting[0].field}`,
    };
  }

  if (gridFilters) {
    if (gridFilters.quickFilterValues) {
      query = { ...query, search: gridFilters.quickFilterValues };
    }
    if (gridFilters.items?.length) {
      query = {
        ...query,
        ...gridFilters.items.reduce(
          (acc, item) => ({ ...acc, [item.field]: item.value }),
          {},
        ),
      };
    }
  }
  if (filters) {
    query = { ...query, ...filters };
  }

  return query;
};

export const api = createApi({
  reducerPath: "api",

  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}`,
    prepareHeaders(headers, { getState }) {
      const state = getState() as RootState;
      const token = state.auth.accessToken;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  tagTypes: ["Auth"],

  endpoints: () => ({}),
});

export default api;
