export default class WebSocketWrapper {
  public socket: WebSocket | null = null;

  constructor() {
    this.socket = null;
  }

  public connect(url: string) {
    if (!this.socket) {
      this.socket = new WebSocket(url);
    }
  }

  public reconnect() {
    if (this.socket?.CLOSED) {
      const url = this.socket.url;
      this.socket = new WebSocket(url);
    }
  }

  public disconect() {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
  }

  public send(message: string) {
    if (this.socket) {
      this.socket.send(message);
    }
  }

  public on(eventName: string, callback: Function) {
    if (this.socket) {
      this.socket.addEventListener(eventName, callback as any);
    }
  }
}
