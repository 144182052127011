import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { Strategy, useListStrategiesQuery } from "features/trading/accounts";
import { useState, useMemo } from "react";
import { useDebounce } from "use-debounce";

interface Props {
  onChange?: (value?: number) => void;
}

export default function StockPicker({ onChange }: Props) {
  const [stockSearchTerm, setStockSearchTerm] = useState<string>("");
  const [debouncedSearchTerm] = useDebounce(stockSearchTerm, 250);

  const { data, isLoading } = useListStrategiesQuery({
    filters: { search: debouncedSearchTerm },
  });

  const options = useMemo(() => {
    if (isLoading || !data) {
      return [];
    }

    const { results: strats } = data;

    onChange && onChange(strats[0]?.id);

    return strats.map((strat: Strategy) => ({
      id: strat.id,
      label: strat.name,
    }));
  }, [isLoading, data]);

  if (isLoading || !data) {
    return <CircularProgress />;
  }

  return (
    <Autocomplete
      options={options}
      defaultValue={options[0]}
      sx={{ width: 150 }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: 5, // Adjust the border radius here
            },
          }}
          onChange={(e) => setStockSearchTerm(e.target.value)}
        />
      )}
      onChange={(_, record) => onChange && onChange(record?.id)}
    />
  );
}
