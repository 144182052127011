import { Button } from "@mui/material";
import { NOTISTACK_ALERT_OPTIONS } from "constants/index";
import { setUnseenCount } from "features/alerts/alertsSlice";
import { useAlerts, useAuth } from "features/store";
import { useGetUnseenAlertCountQuery } from "features/trading/accounts";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const UserAlertsWrapper = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { unseenCount } = useAlerts();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const unseenNotificationShownRef = useRef(false);

  const { data } = useGetUnseenAlertCountQuery(
    {
      user_id: user?.id,
      alert_type: "Position Mismatch",
    },
    {
      pollingInterval: 15_000,
      skip: !user,
    }
  );

  const enqueueAlertsNotification = useCallback((text: string) => {
    return enqueueSnackbar(text, {
      ...NOTISTACK_ALERT_OPTIONS,
      action: (key: number) => {
        return (
          <>
            <Button size="medium" sx={{ color: "#fff" }} onClick={() => {
              navigate("/alerts");
              closeSnackbar();
            }}>Check</Button>
            <Button size="medium" sx={{ color: "#fff" }} onClick={() => {
              closeSnackbar(key);
            }}>Dismiss</Button>
          </>
        );
      }
    } as any);
  }, [enqueueSnackbar, closeSnackbar, navigate, NOTISTACK_ALERT_OPTIONS]);

  useEffect(() => {
    const unseenNotificationShown = unseenNotificationShownRef.current;
    const prev = unseenCount;
    const cur = data?.count;
    if (!unseenNotificationShown && prev === undefined && cur !== undefined) {
      enqueueAlertsNotification(`You have ${cur} unseen alert(s)!`);
      unseenNotificationShownRef.current = true;
    } else if (prev && cur && prev < cur) {
      enqueueAlertsNotification(`You've got ${cur - prev} new alert(s)!`);
    }

    if (cur !== undefined) {
      dispatch(setUnseenCount(cur));
    }
  }, [unseenNotificationShownRef, data]);

  return <></>;
};

export default UserAlertsWrapper;
