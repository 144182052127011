import {
  Typography,
  Box,
  TextField,
  CircularProgress,
  Button,
} from "@mui/material";
import { useForm, SubmitHandler } from "react-hook-form";
import { useLoginMutation } from "features/auth/auth";
import { useAuth } from "features/store";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LOGO_URL } from "config";

type LoginInputs = {
  username: string;
  password: string;
};

export default function Login() {
  const [login, { isLoading }] = useLoginMutation();
  const { isAuthenticated } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginInputs>();

  const onSubmit: SubmitHandler<LoginInputs> = (data) => {
    login(data);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  return (
    <Box
      bgcolor={"white"}
      padding={5}
      borderRadius={3}
      boxShadow={2}
    >
      <Box flexDirection="row" display="flex" justifyContent={"space-between"} alignItems={"center"} marginBottom={5} >
                  <img width={300} src={LOGO_URL} />
        <Typography variant="h5">
          Log In
        </Typography>
      </Box>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        marginTop={1}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          autoComplete="username"
          autoFocus
          {...register("username")}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          {...register("password")}
        />
        {isLoading ? (
          <CircularProgress sx={{ mt: 2, mb: 2 }} />
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Log In
          </Button>
        )}
      </Box>
    </Box>
  );
}
