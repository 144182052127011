import { Card, Grid, IconButton, Tooltip } from "@mui/material";
import {
  GridColDef,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import {
  ServerDataGrid,
  useSorting,
  usePagination,
  useGridFilters,
} from "components/ui/ServerDataGrid";
import { useAppDispatch, useAuth, useTrading } from "features/store";
import { Client, useListClientsQuery, useRemoveClientMutation } from "features/trading/accounts";
import { setCurrentClient } from "features/trading/tradingSlice";
import AddIcon from "@mui/icons-material/Add";
import TrashIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import { ClientModal } from "./ClientModal";
import { FormMode } from "constants/index";


interface ClientListToolbarProps {
  openModal: () => void;
}

const ClientListToolbar = ({ openModal }: ClientListToolbarProps) => (

  <GridToolbarContainer>
    <GridToolbarQuickFilter sx={{ width: 150 }} />
    <Tooltip title="Add new client">
      <IconButton onClick={openModal}>
        <AddIcon />
      </IconButton>
    </Tooltip>
  </GridToolbarContainer>
);

export default function ClientsList() {
  const { isAuthenticated } = useAuth();
  const { currentClientId } = useTrading();
  const dispatch = useAppDispatch();

  useEffect(() => () => { dispatch(setCurrentClient(undefined)) }, []);

  const [sorting, setSorting] = useSorting("name");
  const [pagination, setPagination] = usePagination();
  const [gridFilters, setGridFilters] = useGridFilters();

  const { data, isLoading } = useListClientsQuery(
    {
      sorting,
      pagination,
      gridFilters: gridFilters,
    },
    { skip: !isAuthenticated },
  );

  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [modalFormMode, setModalFormMode] = useState<FormMode>(FormMode.CREATE)
  const [client, setClient] = useState<Client | undefined>();
  const openModal = (mode: FormMode = FormMode.CREATE, client: Client | undefined = undefined) => {
    setModalFormMode(mode);
    setClient(mode == FormMode.EDIT ? client : undefined);
    setIsModalOpened(true);
  };

  const closeModal = () => setIsModalOpened(false);

  const [removeClient] = useRemoveClientMutation();

  const columns: GridColDef<Client>[] = [
    { field: "id", headerName: "ID", width: 70, minWidth: 70 },
    { field: "orion_client_id", headerName: "Orion ID", width: 70, minWidth: 70 },
    { field: "name", headerName: "Name", minWidth: 150, flex: 2 },
    {
      field: "representative__name",
      headerName: "Representative",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "delete",
      headerName: "Actions",
      minWidth: 150,
      renderCell: (params) => {
        const { row } = params;
        const { id, orion_client_id } = row;
        return (
          <Grid sx={{ margin: 1 }}>
            <Tooltip title="Edit client">
              <IconButton onClick={() => openModal(FormMode.EDIT, row)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Remove client">
              <IconButton onClick={() => removeClient(id)} disabled={orion_client_id > 0}>
                <TrashIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )
      }
    }
  ];

  return (
    <>
      <Card>
        <ServerDataGrid
          slotProps={{ toolbar: { openModal }}}
          slots={{
            toolbar: ClientListToolbar,
          }}
          rows={data?.results || []}
          rowCount={data?.count || 0}
          loading={isLoading}
          columns={columns}
          paginationModel={pagination}
          sortModel={sorting}
          filterModel={gridFilters}
          rowSelectionModel={currentClientId ? [currentClientId] : []}
          onPaginationModelChange={setPagination}
          onSortModelChange={setSorting}
          onFilterModelChange={setGridFilters}
          onRowSelectionModelChange={(selectedRows) =>
            dispatch(setCurrentClient(selectedRows[0] || null))
          }
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
        />
      </Card>
      <ClientModal isOpened={isModalOpened} onClose={closeModal} mode={modalFormMode} client={client} />
    </>
  );
}
