import { createSlice } from "@reduxjs/toolkit";

const DRAWER_WIDTH_OPEN = 200;
const DRAWER_WIDTH_COLLAPSED = 55;

export interface LayoutState {
  drawerOpen: boolean;
  drawerWidth: number;
}

const initialState: LayoutState = {
  drawerOpen: true,
  drawerWidth: 200,
};

const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    toggleDrawer: (state) => {
      state.drawerOpen = !state.drawerOpen;
      if (state.drawerOpen) {
        state.drawerWidth = DRAWER_WIDTH_OPEN;
      } else {
        state.drawerWidth = DRAWER_WIDTH_COLLAPSED;
      }
    },
  },
});

export default layoutSlice.reducer;
export const { toggleDrawer } = layoutSlice.actions;
