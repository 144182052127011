import { Box, Switch } from "@mui/material";
import {
  GridColDef,
  GridRenderCellParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import StockPicker from "components/holdings/StockPicker";
import {
  ServerDataGrid,
  dateFormatter,
  useGridFilters,
  usePagination,
  useSorting,
} from "components/ui/ServerDataGrid";
import dayjs from "dayjs";
import { useAppDispatch, useAuth, useTrading } from "features/store";
import {
  AccountStrategy,
  useListAccountStrategiesQuery,
  useUpdateAccountStrategyMutation,
} from "features/trading/accounts";
import { setCurrentAccount } from "features/trading/tradingSlice";
import { useEffect, useMemo, useState } from "react";

interface ToolbarProps {
  setStrategyId: (value?: number) => void;
}

const CompositeAccountsToolbar = ({ setStrategyId }: ToolbarProps) => (
  <GridToolbarContainer>
    <StockPicker onChange={setStrategyId} />
    <GridToolbarQuickFilter sx={{ width: 150 }} />
    <Box sx={{ flexGrow: 1 }} />
    <GridToolbarColumnsButton />
    <GridToolbarExport />
  </GridToolbarContainer>
);

export default function CompositeAccountsList() {
  const { isAuthenticated } = useAuth();
  const dispatch = useAppDispatch();
  const { currentAccountId } = useTrading();

  const [sorting, setSorting] = useSorting("client__name");
  const [pagination, setPagination] = usePagination();
  const [gridFilters, setGridFilters] = useGridFilters();

  const [strategyId, setStrategyId] = useState<number | undefined>();
  const {
    data: accounts,
    isLoading,
    isFetching,
  } = useListAccountStrategiesQuery(
    {
      pagination,
      sorting,
      gridFilters,
      filters: { strategy_id: strategyId },
    },
    { skip: !isAuthenticated },
  );
  const [updateAccountStrategy] = useUpdateAccountStrategyMutation();

  const rows = useMemo(() => {
    if (isLoading || !accounts) {
      return [];
    }

    return accounts.results.map((acc: AccountStrategy) => {
      const now = dayjs();
      const compSince = now.subtract(Math.random() * 365, "day");
      const compEnd = now.add(Math.random() * 365, "day");
      return {
        ...acc,
        created_on: compSince,
        updated_on: compEnd,
      };
    });
  }, [isLoading, accounts]);

  useEffect(() => {
    if (
      accounts &&
      accounts.results.length > 0 &&
      (!currentAccountId ||
        !accounts.results.some((account) => account.id === currentAccountId))
    ) {
      dispatch(setCurrentAccount(accounts.results[0].id));
    }
  }, [accounts, currentAccountId, dispatch, setCurrentAccount]);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90, filterable: false },
    { field: "client__name", headerName: "Client Name", width: 150 },
    {
      field: "account__number",
      headerName: "Account #",
      width: 150,
      filterable: false,
    },
    {
      field: "custodian__name",
      headerName: "Custodian",
      width: 150,
      filterable: false,
    },
    {
      field: "composite",
      headerName: "Composite",
      width: 150,
      filterable: false,
      renderCell: ({ value, row: { id } }: GridRenderCellParams) => (
        <Switch
          defaultChecked={value}
          onChange={(e) =>
            updateAccountStrategy({ id, composite: e.target.checked })
          }
        />
      ),
    },
    {
      field: "created_on",
      headerName: "Composite Since",
      width: 150,
      filterable: false,
      renderCell: dateFormatter,
    },
    {
      field: "updated_on",
      headerName: "Composite End",
      width: 150,
      filterable: false,
      renderCell: dateFormatter,
    },
    {
      field: "notes",
      headerName: "Notes",
      flex: 1,
      filterable: false,
    },
  ];

  return (
    <ServerDataGrid
      slots={{ toolbar: CompositeAccountsToolbar }}
      slotProps={{ toolbar: { setStrategyId } }}
      rows={rows}
      rowCount={accounts?.count || 0}
      loading={isLoading || isFetching}
      columns={columns}
      paginationModel={pagination}
      sortModel={sorting}
      filterModel={gridFilters}
      onSortModelChange={setSorting}
      onFilterModelChange={setGridFilters}
      onPaginationModelChange={setPagination}
      rowSelectionModel={currentAccountId ? [currentAccountId] : []}
      onRowSelectionModelChange={(selectedRows) => {
        if (selectedRows.length && selectedRows[0] !== currentAccountId) {
          dispatch(setCurrentAccount(selectedRows[0] || null));
        }
      }}
      initialState={{
        columns: {
          columnVisibilityModel: {
            id: false,
            client: false,
            orion_account_id: false,
          },
        },
      }}
    />
  );
}
