import { Box, CircularProgress, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts";
import { asCurrency } from "components/ui/formatters";
import { useGetStatsQuery } from "features/trading/accounts";

export const AumChart = () => {
  const { data, isLoading } = useGetStatsQuery();

  if (!isLoading && data && data.results?.length > 0) {
    return (
      <Box>
        <Typography variant="h5">
          AUM: {asCurrency(data.results[data.results.length - 1].total_market_value)}M
        </Typography>
        <BarChart
          series={[
            { data: data.results.map((result) => result.total_market_value) },
          ]}
          height={290}
          xAxis={[
            {
              data: data.results.map((result) => result.as_of_date),
              scaleType: "band",
            },
          ]}
          sx={{ width: 100, height: 100, color: "blueviolet" }}
        />
      </Box>
    );
  } else {
    return <CircularProgress />;
  }
};
