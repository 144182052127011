import { createSlice } from "@reduxjs/toolkit";

export interface AlertsState {
  unseenCount?: number;
}

const initialState: AlertsState = {
  unseenCount: undefined,
};

const alertsSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    setUnseenCount: (state, action) => {
      state.unseenCount = action.payload;
    },
  },
});

export default alertsSlice.reducer;
export const {
  setUnseenCount,
} = alertsSlice.actions;
