import { Button } from "@mui/material";
import { useTrading } from "features/store";
import { useFillStrategyStockWithAccountsMutation } from "features/trading/accounts";

export const FillWithAccountsButton = () => {
  const { currentStrategy } = useTrading();
  const [fillWithAccounts, { isLoading }] =
    useFillStrategyStockWithAccountsMutation();
  return (
    <Button
      variant="outlined"
      sx={{ marginX: 1 }}
      disabled={!currentStrategy || isLoading}
      onClick={() => fillWithAccounts({ id: currentStrategy!.id })}
    >
      Fill with accounts
    </Button>
  );
};
