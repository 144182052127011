import CompositeAccountsList from "components/clients/CompositeAccountsList";
import Layout from "../components/Layout";
import StockPicker from "components/holdings/StockPicker";
import { Box, Card } from "@mui/material";

export default function HomePage() {
  return (
    <Layout title="Underlying Composite" content="Underlying Composite">
      <Card>
        <Box margin={2}>
        <CompositeAccountsList />
        </Box>
      </Card>
    </Layout>
  );
}
